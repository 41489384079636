.page-footer{
  background: $colorFooter;
  margin-top: 10rem;
}
.footer{
  &-content{
    @include content(outer);
    padding: {
      top: 8rem;
    };
    @include fontDefault(1.6, null, $colorWhite);
    .inner-container{
      @include flex(space-between, flex-start, $gap: 6.6)
    }
    h3{
      padding-top: 0;
    }
    h4{
      @include fontDefault(1.6, $color: $colorWhite, $weight: 500);
      padding-top: 0;
      margin-bottom: 0;
    }
    a{
      @include fontDefault(1.6, $color: $colorWhite, $weight: normal);
    }
  }
  &-top, &-main{
    @include border($bottom: true);

    .inner-container{
      padding:{
        left: 0;
        right: 0
      };
    }
  }
  &-top {
    padding: {
      bottom: 4.5rem;
    };
    &-service{
      li{
        @include flex;
        span{
          @include width($math: "-", $gap: 7);
          margin-left: auto;
        }
        &.hotline{
          margin: 1.5rem 0;
          a, i{
            @include fontDefault(2, normal, $colorTertiary, 700);
          }
          i{
            margin-right: 1rem;
          }
        }
      }
    }
    &-imprint{
      .inner-container{
        @include flex(space-between, $gap: 4);
        padding: 0;
        > ul{
          @include flex($direction: column, $gap: 2.5);
          &:last-child{
            max-width: 60%;
          }
        }
      }
    }
    &-imprint, &-service{
      h3{
        margin-bottom: 5rem;
      }
    }
    &-logo {
      max-width: 20rem;
    }
  }
  &-main{
    .inner-container{
      padding: 4rem 0;
    }
    ul{
      @include flex($gap: 2.1);
    }
    .section{
      @include width(50%, $gap: 6.6);
    }
    &-seals{
      ul{
        gap: 5rem;
      }
      li{
        @include width(50%, 5, $max-width: true);
         @include flex(null, center, $gap: 2.1);
        @include fontDefault(1.4, 1.8rem, null);
        span{
          a{
            display: block;
            @include fontDefault(1.4, 1.8rem, null, 700);
          }
        }
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  &-bottom{
    padding: 3.5rem 0;
    .inner-container{
      padding: 0;
      @include flex(center);
    }
  }
}