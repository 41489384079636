.minicart {
  &-items{
    .cart{
      &-price{
        @include fontDefault(1.6, normal, null, 400)
      }
      &.item.message {
        display:none;
      }
    }
    .product{
      text-align: center;
      position: relative;
      z-index: 1;
      &.options{
        display: none;
      }
      &-item{
        width: 100%;
        padding: 0 {
          bottom: 2rem;
        };
        margin-bottom: 2rem;
        @include fontDefault(1.6, normal);
        @include border(1px, $bottom: true);
        &:last-child{
          margin-bottom: 0;
          padding-bottom: 0;
          @include border(0);
        }
        .product{
          @include flex(space-between, flex-start, $gap: 2rem);
          text-align: left;
          &.options{
            @include flex($direction: column, $gap:0);
            @include fontDefault(1.5);
            &.active{
              .toggle{
                &:after{
                  content: "-";
                }
              }
            }
          }
          .subtitle{
            display: none;
          }
          .toggle{
            width: 100%;
            cursor: pointer;
            @include flex(space-between, center);
            &:after{
              content: "+";
            }
          }
          .content{
            width: 100%;
            background: $colorGrey;
            padding: .3rem 1rem;
            .label{
              @include fontDefault($weight: 600);
              margin-top: .5rem;
            }
          }
        }
        &-details{
          width: 100%;
          text-align: left;
          padding-left: 2rem;
        }
        &-name{
          background: transparent;
          padding:0;
          font-weight: 400;
        }
      }
      &-image{
        &-container{
          display: inline-block;
        }
      }
    }
  }
  &-wrapper {
    text-align: center;
    position: relative;
    .counter {
      @include overlay(-.6rem, 50%, null, null);
      transform: translate(-50%, 0);
      margin-left: 2rem;
      height: 18px;
    }
    > a {
      &:before {
        @include fontIcon($color:$colorSecondary);
        content: $iconCart;
        width: 100%;
        text-align: center;
      }
      .text {
        color:$colorError;
        background-color: yellow;
        &.empty {
          color:$colorSecondary;
          background-color: transparent;
        }
      }
      .counter {
        background-color: yellow;
        &.empty {
          background-color: transparent;
          .counter {
            &-number {
              display: none;
            }
          }
        }
        &-number {
          //background: $colorWhite;
          display: inline-block;
          //@include border($color: $colorSecondary);
          width: 2.2rem;
          height: 2.2rem;
          @include fontDefault(1.2, 2rem, $colorError, 800);
          text-align: center;
          border-radius: 50px;
          //letter-spacing: -1px;
        }
        &-label {
          display: none;
        }
      }
    }
    a.action{
      @include flex($direction: row, $wrap: true);
      line-height: normal;
    }
  }
}