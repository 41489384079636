.page-header{
  @include transition;
  @include overlay(0,0,100%, auto, sticky, $z-index: 3);
  /*.checkout-index-index &{

    &.sticky{
      top: 0;
    }
    .header{
      &-main-container{
        @include border(0);
        margin-bottom: 0;
      }
    }
  }*/
  &.sticky{
    z-index: 3;

    .header {
      &-content-inner{
        z-index: 10;
        width: 100%;
        height: auto;
        @include border($bottom: true);
      }
    }
  }

}
.header{
  &-content-inner{
    @include transition;
    margin-top: 0;
    position: relative;
    z-index: 2;
    background: $colorWhite;
    width: 100%;
    @include border($color: transparent, $bottom: true);
  }
  &-main, &-top{
    &-container{
      @include flex(space-between);
    }
    ul{
      @include flex(flex-start, center);
    }
    .inner-main-container{
      @include flex(flex-start, center);
      &.left {
        max-width:170px;
      }
      &.middle {
        .header-main-info {
          text-align: center;
          width: 100%;
          font-weight:normal;
          span {
            &.up {
              @include fontDefault(3.2, 3rem);
              text-transform: uppercase;
            }
            &.down {
              @include fontDefault(1.8, 2rem);
              display: block;
            }
          }
        }
      }
    }
  }
  &-main{
    @include content(outer);
    ul{
      gap: 3rem;
    }
    &-logoalt{
      margin-right: 4rem;
    }
    &-container{
      padding: 1.9rem 0;
      margin-bottom: 1.9rem;
      @include border($bottom: true);
    }
    &-account{
      ul{
        li{
          width: 100%;
          text-align: center;
          @include fontDefault($fontButton , 2.2rem, $colorSecondary, normal, uppercase, $font: true);
          a{
            @include flex(center, $direction: row, $wrap: true, $gap: 1.1);
            @include transition;
            color: $colorSecondary;
            i{
              @include fontIcon($color: $colorSecondary);
              display: block;
              width: 100%;
            }
            div{
              span{
                line-height: normal;
                vertical-align: bottom;
              }
            }
          }
        }
      }
    }
    &-seals{
      @include border($right: true);
      padding-right: 3rem;
      margin-right: 3rem;

      .logo-wrapper {
        width: 60px;
        > img {
          object-fit: contain;
          width: inherit;
          image-rendering: -webkit-optimize-contrast;
        }
      }
    }
  }
  &-top{
    background: $colorSecondary;
    color: $colorWhite;
    @include transition;

    position: relative;
    &-container{
      @include content(outer);
      @include fontDefault(1.4, normal);
      .inner-top-container{
        @include flex(null, center);
      }
      i{

        @include fontIcon(1.4);
        color: $colorWhite;
        margin-right: 1rem;

      }

      a{
        color: $colorWhite;
        display: inline-block;
        padding: {
          top: 1.2rem;
          bottom: 1.2rem;
        };
      }
    }
    &-links{
      ul{
        @include flex(flex-start, center, $gap: 3);

        li {
          @include flex(flex-start, center);
        }
      }
    }
    &-language{
      margin-right: 4rem;

      .switcher-dropdown {
        gap:2rem;
        max-height: 41px;

        .switcher-option {
          a {
            padding: 0;
            vertical-align: middle;

            img {
              max-width:28px;
              max-height:28px;
              vertical-align: middle;
              display: block;
            }
          }
        }
      }
    }
    &-hotline{
      color: $colorWhite;
      .phone{
        background: $colorTertiary;
        @include overlay(0, 0, auto, calc(100% + 1rem),  $right: true, $z-index: 3);
        @include flex(center, center);
        i{
          padding: 0 1rem;

          background: $colorTertiary;
          line-height: normal;
          @include flex(center, center);
          height: 100%;
        }
        &:after{
          @include overlay(-.5rem, 3rem, 1.5rem, 1.5rem, $bottom: true, $right: true, $z-index: -2);
          content: "";
          border-left: 1rem solid transparent;
          border-right: 1rem solid transparent;
          border-top: 1rem solid #FFC774;
        }
      }
      ul{
        gap: 1rem;
      }
      a{
        @include flex(centery);
        position: relative;
        padding-right: 5rem;
        font-weight: bold;
      }
      i{
        transform: rotateY(180deg);
        display: inline-block;
        font-size: 2rem;
        margin: 0;
        width: auto;
      }
    }
  }
  &-bottom{
    @include flex(flex-start, center, $gap: 2.8);
    @include content(outer);
    @include transition;
    padding-bottom: 1rem;
    ul{
      @include flex($gap: 2.8);
      li{

      }
    }
    &-links{
      width: 100%;

      ul{
        max-width: 65rem;
        li{
          width: 100%;

          text-align: center;
          a{
           @include fontDefault(1.4, normal, $colorFontDefault, normal, uppercase);
            @include transition;
            &:hover{
              color: $colorSecondary;
            }
          }
        }
      }
    }
    &-search{
      margin-left: auto;
      width: 25%;
    }
  }
}