.webcrossing-widget-image-slider{
  position: relative;
  z-index: 1;
  padding-top: 0;
  .image{
    img{
      width: 100%;
    }
  }
  .block-content{
    .splide {
      &__pagination{
        &__page {
          background: $colorTertiary;
          width: 13px;
          height: 13px;
          margin: 5px;
          &.is-active{
            background: $colorSecondary;
            transform: scale(1);
          }
        }
      }
      &__arrow {
        background: rgba($colorPrimary, .4);
        border-radius: 0;
        height: auto;
        width: auto;
        opacity: 1;
        padding: 1rem;
        svg{
          display: none;
        }
        &--prev, &--next {
          transition: all ease .2s;
          position: absolute;
          &:before{
            @include fontIcon(4);
            content: $iconArrow;
            color: $colorWhite;
          }
        }
        &--prev {
          left: 5rem;
          &:before {
            transform: rotate(-90deg);
          }
        }
        &--next {
          right: 5rem;
         /* width: 1.8rem;
          height: 7.5rem;*/
          &:before {
            transform: rotate(90deg);
          }
        }
      }
    }
    ul{
      li{
        /*position: relative;
        overflow: hidden;
        transition: none !important;
        visibility: hidden;
        display: none;

        &.is-active {
          visibility: visible;
          display: block;
        }*/

        .image{
          position: relative;
        }
      }
    }
    .overlay{
      @include overlay(0, 50%);
      transform: translate( -50%, 0);
      @include flex(flex-end, flex-start , $wrap: true);
      z-index: 3;
      text-align: left;
      padding-top: 2%;
      @include content(outer);
      &-link{
        @include overlay($z-index: 5);
      }
      &-inner{
       /* max-width: 90%;*/

      }
      .label, .text{
        max-width: $mediumContent;
      }
      .label{
        @include flex(flex-start, center, $gap: 2);
        @include fontDefault(1.4, 1.4rem, $colorWhite, 700, uppercase);
        margin-bottom: -.9rem;
        position: relative;
        z-index: 1;
        height: 4.6rem;
        &s{
          @include flex(space-between, flex-start, $gap: 2);
          width: 100%;
          padding: 0 2.4rem;
        }
        &.sub{
          background: $colorPrimary;
          @include fontDefault(2.6, normal, $colorWhite, 700, uppercase);
          padding: .6rem 2.4rem;
          margin-left: auto;
        }
        img{
          max-height: 100%
        }
      }
      .text{
        background: $colorTertiary;
        padding: 2.4rem ;
        color: $colorWhite;
        @include fontDefault(1.8, 2.6rem, $colorWhite, 300, uppercase, $fontStyle: italic);
        width: auto;
        h2{
          padding-top: 0;
          @include fontDefault(5, 8rem, $colorWhite, normal, $fontStyle: normal);
        }
      }
      &.right{
        right: 5%;
        transform: translate(-50%, -50%);
        text-align: right;
        h2{
          text-align: right;
        }
      }
      &.center{
        @include flex(null, center);
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        h2{
          text-align: center;
        }
      }
      &.left{
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @include breakpoint(tablet){
        width: 100%;
        padding-top: 10%;
      }
      h2{
        @include fontDefault(4, 6rem, $colorWhite, 600, uppercase);
        padding-top: 2.1rem;
        width: auto;
      }
      h3{
        padding-top: 1rem;
        width: auto;
        @include fontDefault(2.5, 3.8rem, $colorWhite, normal, uppercase);
      }
      > p{
        letter-spacing: 1.25px;
        font-size: 1.87rem;
        text-align: left;
      }
      .actions{
        @include flex;
        padding-top: 1.2rem;
        a{
          @include buttonWhite;
          @include flex(centery);
          text-transform: uppercase;
        }
      }
      &.white{
        color: $colorWhite;
        h2{
          color: $colorWhite;
        }
      }
    }
  }
  @include breakpoint(smallDesktop){
    .block-content {
      ul li .info {
        h2 {
          @include fontDefault(2.8, normal);
        }

        h3 {
          @include fontDefault(2, normal);
        }
      }
    }
  }
  @include breakpoint(tablet){
    .block-content {
      .splide__arrow {
        &--prev {
          left: 1rem;
        }
        &--next{
          right: 1rem;
        }
      }
      ul li .overlay {
        .actions {
          gap: 2rem;

          a {
            background: transparent;
            margin: {
              top: 0;
            };
          }
        }
        .text{
          h2 {
            @include fontDefault(3, normal, $colorWhite);
          }
        }
      }
    }
  }
  @include breakpoint(phone){
    .block-content ul li .overlay .text {
      max-width: 100%;
      width: 100%;
    }
  }
}