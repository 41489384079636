.cart {
  &-price {
    @include fontDefault(2, 2rem, $weight:  bold,$letterSpacing: 0);
  }
  &-sidebar{
    margin-top: 2.8rem;
  }
  &-grow-my-tree {
    color: #224c00;
    margin: 1rem 0;

    a {
      color: #224c00;
      text-decoration: underline;
    }
  }
  &-information {
    margin-top: 2rem;
    a {
      color: $colorSecondary;
    }
  }
  &-container {
    @include flex(space-between, flex-start, row-reverse, wrap);
    #gift-options-cart {
      display: none;
    }
    > form {
      width: calc(100% - 36rem) !important;
      .actions {
        padding-top: 2rem;
        @include flex(null, flex-end);
        .action {
          width: 33%;
          &.continue {
            margin-left: 0;
          }
        }
        &-toolbar {
          @include flex(null, center);
          .towishlist span {
            display: none;
          }
        }
        &-edit {
          display: none;
        }
      }
    }
    caption.table-caption {
      display: none;
    }
  }
  &-summary {
    width: 33rem;
    background: $colorLightGrey;
    padding: 1.8rem;
      > .title {
          @include fontDefault(2, 1, $weight:  bold);
          padding-bottom: 1.6rem;
          display: block;
          @include border($color: $colorSecondary, $bottom: true);
      }
    .block.shipping {
      display: none;
    }
    .checkout{
      &.methods{
        li{
          width: 100%;
          margin-right: 0;
        }
      }
      .action{
        margin: {
          top: 0;
          right: 0;
        }
      }
    }
    .primary {
      width: 100%;
    }
    .block {
      &.discount {
        cursor: pointer;
        color: $colorPrimary;
        .title{
          border-bottom: 0;
        }
        .field {
          margin-bottom: 1.5rem;
        }
        .fieldset.coupon {
          label {
            display: none;
          }
        }
        .actions-toolbar {
          @include overlay(inherit, inherit, 100%, null, relative);
        }
      }
    }
  }
  &-totals {
    @include border($bottom: true);
    .table {
      &-caption {
        text-align: right;
      }
      &-wrapper {
        position: relative;
        tr.grand.totals {
          color: black;
        }
      }
    }
  }

  @include breakpoint(largeDesktop){
    &-summary{
      #block-discount{
        margin-left: 0;
        width: 100%;
      }
    }
    &-container {
      form {
        width: 100%;
      }
    }
  }
  @include breakpoint(smallDesktop){
    &-container{
      padding-top: 3rem;
    }
    &-membership{
      width: 49%;
      margin: {
        top:0;
        left: 2%;
      }
    }
    &-summary{
      order: 0;
      .summary{
        &.title{
          padding-bottom: 3rem;
        }
      }
    }
  }
  @include breakpoint(tablet){
    &-sidebar{
      @include flex($wrap: true);
    }
    &-summary{
      width: 100%;
      margin-top: 7rem;
    }
    &-container {
      @include flex($direction: column-reverse, $flex: false);
      form.form-cart{
        width: 100% !important;
        order: 0;
      }
    }
    &-membership, &-summary{
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
    &-membership{
      margin-top: 5rem;
    }
    &-container{
      form {
        .actions {
          @include flex($wrap: true);
          padding-top: 2rem;
          > .action{
            margin: {
              left:0;
              bottom: 1rem;
            }
            line-height: normal;
            width: 100%;
          }
        }
      }
    }
  }
}


