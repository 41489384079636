$mmenu : left;
@mixin mobileMenu($state: null, $local: $mmenu){
  @if $state == close {
    position: fixed;
    top: 0;
    width: 45rem;
    max-width: calc(100% - 6.8rem);
    height: 100%;
    #{$mmenu}:-90%;
  } @else if $state == open {
    #{$mmenu}:0;
  }
}

.mmenu, .page {
  &-wrapper{
    @include transition;
  }
}
.page-wrapper{
  /*overflow-x: hidden;*/
  .mmenu-opened &{
    max-height: 100vh;
    overflow: hidden;
  }
}
body{

}
.mmenu {
  &-opened{
    overflow: hidden;

  }
  &-content{
    width: 45rem;
    max-width: calc(100% - 6.8rem);
    background: $colorSecondary;
    overflow: auto;
    max-height: 100vh;

  }
  &-wrapper {
    display: none;
    @include overlay(0, null, 100%, auto, fixed, $z-index: 2147483647);
    overflow: auto;
    #{$mmenu}: -100%;
    background: rgba($colorPrimary, .9);
    min-height: 100vh;
    &.opened{
      @include mobileMenu(open);
      max-width: 100%;

      .mmenu-header-close{
        @include overlay(2rem, null, auto, auto, fixed);
        #{$mmenu}: calc(100% - 4.6rem);
      }
    }
    .navigation {
      > ul {
        li{
          &.level0{
            > a{
              border:0;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
      ul{
        @include flex($wrap: true);
        li{
          @include flex($direction: column);
          width: 100%;

          &.level0{

            > a{
              padding:2rem 3.5rem;
            }
            &:hover{
              a{
                color: $colorWhite
              }
            }

          }
          a{
            color: $colorWhite;
            width: 100%;
          }
        }
      }
    }
  }
  &-header, &-top{

    &-close{
      @include overlay(2rem, null, auto, auto, relative);
      @include transition;
      .nav-toggle{
        background: transparent;
        &:before, &:after{
          display: none;
        }
        span{
          &:before{
            content: $iconClose;
          }
          background: transparent;
          @include fontIcon(4, 300, $colorWhite, normal);
          height: auto;
        }
      }
    }
  }
  &-header{
    @include flex($wrap: true);
    overflow-y: visible;
    @include flex(space-between, null);
    background: $colorPrimary;
    width: 100%;
    padding: 0 2rem;
    .mmenu-header-hotline{
      @extend .header-top-hotline;
      width: 100%;
      @include fontDefault(1.4, normal, $colorWhite);
      li{
        &:first-child{
          display: none;
        }
        .phone{
          left: 0;
          right: initial;
          &:after{
            left: 3rem;
            right: initial;
          }
        }
        a{

          padding: 1rem{
            left:5rem;
          };
          color: $colorWhite;
        }
      }

    }
    ul{
      @include flex(space-between, center);
      width: 100%;
    }
    &-search{
      padding: 1rem 0;
      width: 100%;
      .block {
        &-search{
          .block {
            &-title{
              display: none;

            }
            &-content{

              padding: 0;
              max-width: 100%;
              overflow: inherit;
              text-align: left;
              @include overlay(0, 0, 100%, auto, relative, $z-index: 0, $right: true);
              background: $colorSecondary;
              .control{
                width: 100%;
              }
              .label{
                display: none;
              }
              form {
                @include flex;
                @include border(0);
                .search {
                  width: 100%;
                  align-items: initial;


                }
                input {
                  @include border(0);
                  width: 100%;
                  background:$colorWhite;
                }
                .actions {
                  width: auto;
                  button {
                    display: block;
                    background: $colorTitleDefault;
                    padding: 1rem 2rem 0;
                    @include flex(center, center);
                    span{
                      display: none;
                    }
                    &:before{

                      display: block;
                    }
                  }
                }
              }
              .actions{

              }
              .search{
                width: 100%;

              }
            }
          }
        }
        &-title{
          display: none;
        }

      }
    }
    &-account{
      padding: 1rem 0;
      text-align: center;
      a{
        display: block;
        @include fontDefault(2, null, $colorWhite, 600);
        &:before{

          display: inline-block;
          padding-right: 1rem;

        }
      }

    }
    &-account, &-language{
      width: auto;
    }
    &-language{
      /*@extend .header-main-language;*/
      .switcher {
        &-trigger{
          padding-left: .5rem;
        }
        .options ul.dropdown {
          height: auto;
        }
      }
      &.section{
        @include flex(flex-end, center);
        .switcher-options li.view {
          &-en, &-de {
            background: $colorWhite;
            a {
              padding: {
                left: .5rem;
                top:1rem;
                bottom:1rem;
              };
            }
          }
        }
      }
    }
  }
  &-main{

    padding:1rem 0 0;
    ul{
      &.level0{
        padding-bottom: 2rem;

      }
    }
    li{
      &.prev{
        span{
          cursor: pointer;
        }
      }
      &.level {
        &0 {
          a{
            border-bottom: 1px solid rgba($colorWhite, .1);
          }
          .submenu{
            li{
              &.level1{
                padding-right: 0;
                > a{
                  padding: 2rem;
                }
              }
              &.level2{
                @include flex(space-between, center,$direction: row-reverse);
                &:before{
                  display: none;
                }
                > a{
                  @include border($color: rgba($colorWhite, .1), $bottom: true);

                }
              }
            }

          }
        }
        &1{

          a{
            padding-top: .5rem;
            padding-bottom: .7rem;
          }
          .mm-btn_next{
            height: 3rem;
          }
        }
      }
      a{
        padding: 2rem 3.5rem;
        box-shadow: none;
        @include border(0);
        &.mm-btn_next{
          height: 6rem;

        }
        &.level-top{
          display: block;
          @include fontDefault(1.7, null, $colorSecondary, bold);
        }

        &:after{
          display: none;
          transition: all ease .2s;
        }
      }
    }
    .navigation.slide{
      overflow: hidden;
      ul{
        @include transition;
        /*transform: translate(-100%, 0);*/
        position: relative;
        left: 100%;
        @include flex($wrap: true);
        li{
          @include transition;
          left: 0;
          position: relative;
          max-width: 100%;
          border-right: 0;
          &.has-active{
            > a{
              background: $colorSecondary;
              color: $colorWhite;
            }
          }
          &.active{
            border-top: 0;
            > a{
              background: $colorTertiary;
              color: $colorWhite;
            }
          }
          &.prev{
            background: $colorLightSecondary;
            @include flex(flex-start, center, $direction: row);
            color: $colorWhite;
            @include fontDefault(1.4, null, $colorWhite);
            &:hover{
              background: $colorLightSecondary;
            }
            .prev{
              display: inline-block;
              padding: 1rem 2rem;
              width: auto;
            }
          }
          a{
            @include transition;
            left: 0;
          }
        }
        &.opened{
          overflow: hidden;
          left: 0;
          position: relative;
          top: 0;
        }
        &.remove{
          > li {
            > a, &.prev {
              position: absolute;
              left: -100%;
            }
            &:not(.opened) {

            }
          }
        }
      }
      > ul{
        position: relative;
        left: 0;
        li{
          &:hover{
            background: transparent;
          }
          a{
            @include flex(space-between, center);
            padding: 2rem;
            font-weight: normal;
            .toggle{
              padding: 0 2rem;
            }
          }
        }
      }
      .submenu{
        @include transition;
        display: block;
        position: absolute;
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        border-top:0;
        padding: 0;
        box-shadow: none;
        top: 0;
        margin-top: 0;
        max-height: initial;
      }
    }
  }
  &-footer{
    background: $colorDarkGrey;
    padding: 0;
    border-top:1px solid $colorWhite;
    text-align: right;
    li {
      padding: 0 2rem;
      display: block;
      border-bottom:1px solid $colorWhite;
      a{
        @include fontDefault(1.6, 4rem, $colorWhite);
      }
      i{
        @include fontIcon(1.4);
        color: $colorWhite;
        margin-right: 1rem;
      }
    }
  }
}

@include breakpoint(smallDesktop) {
  body{
    &:before{
      display: block;
    }
  }
  .mmenu-wrapper {
    @include flex($mmenu, null);
  }
}

