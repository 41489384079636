/*Product Detail*/
.preview-giftcard{
  background-size: 100% !important;
}

.product-info-variants {
  .container {
    margin-top: 2rem;
    display: inline-block;
    border: 1px solid #000;
    padding:1.2rem 3rem;

    h4 {
      padding-top:0;
      font-size: 1.6rem;
      color: #333;
      line-height: 2.6rem;
      font-weight: 700;
    }

    ul {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      li {
        max-width: 30px;
        max-height: 30px;
      }
    }
  }
}

.catalog {
  &-product {
    &-view{
      main{
        .page-wrapper {
          padding-top: 0;
        }
      }
      .column{
        margin-top: 3rem;
      }
      .price{
        &-details{
          padding-bottom: 0;
          @include border(0);
        }
      }
      .product{
        &-item{
          &-info{
            text-align: left;
          }
        }
      }
    }
  }
}