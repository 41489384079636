.block-search{
  @include flex($direction: row-reverse);

  .account &{
    .block-title{
      padding: 0;
      margin: 0;
      @include border(0);
    }
  }
  .block{
    &-title {
      display: none;
      padding: 0;
      margin: 0;
      @include border(0);
      cursor: pointer;
      &:before {
        content: $iconSearch;
      }
      strong {
        display: none;
      }
    }
    &-content {
      width: 100%;
      .action{
        &.search {
          padding: 0;
          @include border(0);
          margin-right: 0;
          display: block;
          background: transparent;
          &:before {
            @include fontIcon(1.6, 500, $colorSecondary);
            margin-right: 2rem;
            content: $iconSearch;
            display: block;
          }
          span {
            display: none;
          }
        }
      }
      form{
        background: $colorLightGrey;
        @include content(outer);
        @include flex(centery, $direction: row-reverse);
        @include border;
      }
      .field.search {
        width: 100%;
        margin-top: 0;
        .control{
          margin: 0;
        }
      }
      input {
        border: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: transparent;
        line-height: 3.7rem;
        padding: .5rem 0;
        @include fontDefault(1.4, 3rem, $colorGrey);
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */

          color: $colorGrey;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $colorGrey;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $colorGrey;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $colorGrey;
        }
      }
      label {
        display: none;
      }
    }
  }

  @include breakpoint(smallDesktop){
    .bottom &{
      width: 100%;
      .block{
        &-content{
          padding: 1.5rem;
          background: $colorWhite;
          form{
            @include border($color: $colorSwatchGrey);
          }
          .action.search::before{
            margin-right: 0;
          }
        }
      }
    }
  }
}