.mage-error {
  color: $colorPrimary;
}
.message {
  display: block;
  margin: 0 auto;
  position: relative;
  padding: 1.5rem 2rem;
  width: 100%;
  > *{
    @include flex(flex-start, flex-start);
    & > *{
      display: contents;
    }
  }
  > *:first-child:before {
    @include fontIcon(2.4, 2.4rem);
    width: 4.5rem;
    text-align: center;
    margin-right: 1rem;
  }
  &.error {
    background: $colorTertiary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconError}";
      }
    }
  }
  &.success {
    background: $colorTertiary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconError}";
      }
    }
  }
  &.info {
    background: $colorTertiary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        width: auto;
        color: #fff;
        content: "#{$iconInfo}";
      }
    }
  }
  &.empty {
    background: $colorPrimary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconInfo}";
      }
    }
  }
  &.warning {
    background: $colorPrimary;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconWarning}";
      }
    }
  }
  &.notice {
    background: $colorRed;
    color: $colorWhite;
    & > *:first-child{
      &:before{
        color: #fff;
        content: "#{$iconWarning}";
      }
    }
  }
  &.success,
  &.notice,
  &.warning,
  &.info,
  &.empty,
  &.error {
    a {
      color: $colorWhite;
      font-weight: bold;
      margin-left: .8rem;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: $colorWhite;
        text-decoration: underline;
      }
    }
  }
  &s {
    .message{
      margin: 1.5rem 0 ;
      position: relative;
      padding: 1.5rem 2rem;
    }

    &.page {
      @include content(outer);
    }
    .cms-home &{
      margin-bottom: 0;
    }
    .checkout-index-index &{
      width: 100%;
      max-width: calc(100% - 39rem);
      @include breakpoint(smallDesktop){
        max-width: 100%;
      }

    }
    .checkout-index-index .payment-method &{
      max-width: 100%;
    }
  }

  .checkout-index-index &{
    max-width: calc(100% - 39rem);
    margin: 0;
    &.error{
      max-width: calc(100% - 39rem);
      margin-bottom: 2rem;
    }
    &.notice{
      margin-bottom: 3rem;
    }
  }
  .checkout-index-index .checkout-shipping-address &, .checkout-index-index .payment-method &{
    max-width: 100%;
  }

  .payment-option &{
    &.error{
      max-width: 100%;
    }
  }
}

.field-error, .mage-error {
  @include fontDefault(1.4, null, $colorError);
  width: 100%;
}
.password {
  &-weak, &-medium, &-strong, &-very-strong{
    @include fontDefault(1.6);
    .password-strength-meter > span{
      @include fontDefault(null, null, $weight: 600);
    }
  }
  &-weak {
    .password-strength-meter > span{
      color: $colorError;
    }
  }
  &-medium{
    .password-strength-meter > span{
      color: $colorInfo;
    }
  }
  &-strong{
    .password-strength-meter > span{
      color: $colorSuccess;
    }
  }
  &-very-strong{
    .password-strength-meter > span{
      color: $colorSuperSuccess;
    }
  }
}