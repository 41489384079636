.checkout{
  &-index{
    &-index{
      .column{
        @include content(outer);
      }
    }
  }
  &-container{
    @include flex(space-between, $wrap: true);
    padding-bottom: 6rem;
  } /* .checkout-container */
  &-agreement {
    @include flex($wrap: true);
    margin: 1rem 0;
    &:first-child{
      margin-top: 0;
    }
    &:last-child{
      margin-bottom: 0;
    }
    input{
      width: auto;
    }
    .action{
      padding: 0 {
        left: 1.2rem;
      };
      margin: 0;
      text-align: left;
      @include fontDefault(1.4, null, $colorPrimary);
      background: transparent;
      @include border(0);
      &:hover{
        background: transparent;
        color: $colorPrimary;
      }
    }
    .label{
      @include width($gap: 3, $max-width:true);
    }
    &s {
      background: $colorWhite;
      margin-top: 2rem;
      margin-bottom: 3rem;
      padding: 1.5rem 2rem;
      @include border;
      &:after{
        display: none !important;
      }
    }
  } /* .checkout-agreement */
  &-shipping{
    &-method{
      margin-top: 4rem;
    }
  } /* .checkout-shipping */
  &-billing{
    &-address{
      margin-bottom: 3rem;
    }
  } /* .checkout-billing */
  &-additional-information{
    @extend .checkout-shipping-method;
  } /* .checkout-additional-information */
  &-onepage-success{
    .page-main .actions-toolbar{
      .action{
        &.primary{
          margin-top: 3rem;
          display: block;
        }
      }
    }
  } /* .checkout-onepage-success */
}
.note{
  @include fontDefault(1.4, normal);
}
.address{
  > .field{
    .control {
      input, select {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
.billing{
  &-address{
    &-details{
      padding-left: 2rem;
      margin-top: 1rem;
      font-size: 1.5rem;
      br{
        display: block;
      }
      span{
        display: block;
      }
      .action{
        display: block;
        margin-top: 1.5rem;
      }
    }
    &-same-as-shipping-block{
      @include flex;
      input{
        width: auto;
      }
      label{
        width: 100%;
        padding-left: 1rem;
        font-size: 1.4rem;
      }
    }
  }
}
#shipping-new-address-form{
  @include flex(space-between, $wrap: true);
  > .field{
    width: 100%;
  }
}
#checkout {
  &-shipping-method-load {
    background: $colorLighterGrey;
    padding: 2rem;
    margin-bottom: 3rem;
  }
}
.step {
  &-title {
    @include fontDefault(2.4, 1, null, bold);
    padding-bottom: 1.6rem;
    display: block;
  }
}
.opc{
  &-estimated-wrapper{
    display: none;
  }
  &-progress-bar {
    padding: initial {
      bottom: 5rem;
      top: 5rem;
    };
    width: 100%;
    @include flex(space-between, null, $gap: .7);
    counter-reset: i;
    max-width: calc(100% - 39rem);
    li {
      margin: 0 {
        bottom: 1rem;
      }
      display: inline-block;
      position: relative;
      text-align: center;
      vertical-align: top;
      width: 100%;
      max-width: 100%;
      &:before {
        background: $colorPrimary;
        @include overlay(4rem, 0, 100%, .1rem);
        content: "";
      }
      &:first-child:before {
        border-radius: 0.6rem 0 0 0.6rem;
      }
      &:last-child:before {
        border-radius: 0 0.6rem 0.6rem 0;
      }
      > span {
        display: block;
        padding-bottom: 4.5rem;
        width: 100%;
        word-wrap: break-word;
        @include fontDefault(null, null, $weight: normal);
        text-align: center;
        &:after {
          @include overlay(3rem, 50%, 2.4rem, 2.4rem);
          background: $colorWhite;
          background-size: 70%;
          border-radius: 50%;
          @include border($color: $colorPrimary);
          @include fontDefault(1.04, null, $colorPrimary,500);
          content: counter(i);
          counter-increment: i;
          @include flex(centery);
          padding: 0;
          transform: translate(-50%, 0);
        }
      }
      &._active {
        opacity: 1;
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorPrimary;
          font-weight: 500;
          &:after {
            color: $colorSecondary;
            border-color: $colorSecondary;
          }
        }
      }
      &._complete {
        cursor: pointer;
        opacity: 1;
        &:before {
          background: $colorSecondary;
        }
        > span {
          color: $colorPrimary;
          &:after {
            content: $iconCheck;
            @include fontIcon(1.6, 900, $colorWhite);
            border-color: $colorSecondary;
            background: $colorSecondary;
            line-height: 2.1rem;
          }
        }
      }
    }
  }
  &-wrapper{
    width: 100%;
    max-width: calc(100% - 39rem);
  }
  &-block {
    &-shipping{
      &-information{
        .action{
          background: transparent;
          &:hover{
            background: transparent;
          }
        }
      }
    }
    &-summary {
      @extend .cart-summary;
      max-width: 100%;
    }
  }
  &-sidebar{
    width: 33rem;
    margin-top: -10rem;
    header{
      display: none;
    }
  }
}
.ship {
  &ping {
    &-address {
      &-item {
        margin: {
          bottom: 2rem;
          right: 2%;
        }
        width: 32%;
        padding: 2rem;
        @include border(2px);
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        &.selected-item {
          border-color: $colorSecondary;
        }
        button.action {
          width: 100%;
          background: $colorTertiary;
          color: $colorWhite;
          margin-top: 2rem;
          margin-left: 0 !important;
          border-color: $colorTertiary;
          &:hover {
            color: $colorTertiary;
            background: transparent;
          }
          &.action-selected-shipping-item {
            background: $colorSecondary;
            border-color: $colorSecondary;
            &:hover {
              color: $colorSecondary;
              background: transparent;
            }
          }
        }
        &s {
          @include flex($wrap: true);
        }
      }
    }
    &-information {
      margin-top: 2rem;
      &-title {
        font-weight: bold;
        padding-bottom: 1.3rem;
        border-bottom: 1px solid $colorBorder;
        margin-bottom: 1.4rem;
        @include flex;
        > span {
          &:first-child {
            width: 100%;
          }
        }
      }
    }
  }
  &-via{
    margin-top: 4rem;
  }
}
.new-address-popup {
  padding: 0 2.2rem;
  button.action {
    width: calc((100% - 13rem) / 3);
    @media (max-width: 768px) {
      width:calc((100% - 5rem) / 2);
    }
    @media (max-width: 480px) {
      width:100%;
    }
  }
}
.items{
  &-in{
    &-cart{
      padding-top: 2rem;
      .title{
        cursor: pointer;
        @include flex(space-between);
        &:after{
          content: $iconArrow;
          @include fontIcon(1.5);
          @include transition;
          transform: rotate(180deg);
        }
      }
      &.active{
        .title{
          &:after{
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
.payment{
  &-method{
    padding: 1.7rem 0;
    @include border($bottom: true);
    &:last-child{
      border-bottom: 0;
      padding-bottom: 0;
    }
    &-title{
      @include flex(null, center);
      input{
        width: auto;
        margin-right: 1rem;
      }
    }
    &-content{
      display: none;
      padding: {
        top: 2rem;
        left: 2.4rem;
      }
    }
    &._active{
      .payment{
        &-method{
          &-content{
            display: block;
            br{
              display: block;
            }
          }
        }
      }
    }
  }
  &-group{
    background: $colorLightGrey;
    padding: 2rem 2.3rem;
  }
  &s{
    .legend{
      @extend .step-title;
      @include border(0);
    }
  }
  &-option {
    @include border($color: $colorGrey, $bottom: true);
    padding-bottom: 1.5rem;
    &-content{
      .messages{
        max-width: 100%;
        .message{
          @include fontDefault(1.4, 2rem);
        }
      }
    }
    &-title {
      margin: 1.5rem 0 0;
      .action{
        padding: 1rem 0;
        text-align: left;
        @include border(0);
        @include fontDefault(2, 1, $colorPrimary, bold);
        display: block;
      }
    }
  }
}
.order{
  &-comment, &-comment-additional{
    margin-bottom: 1rem;
    &-title{
      font-weight: bold;
      .action-toggle {
        background: none;
        color: $colorTitleDefault;
      }
    }
    textarea{
      resize: none;
    }
    &._collapsible{
      margin-bottom: 1rem;
      @include border($bottom: true);
      @include transition;
      .order-comment-title{
        @include flex(space-between, center);
        &:after{
          @include fontIcon(1.4);
          content: $iconArrow;
          transform: rotate(180deg);
          @include transition;
        }
      }
      &._active{
        @include border($color: transparent, $bottom: true);
        .order-comment-title {
          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.amazon-button-container__cell{
  margin-top: 2rem;
}

@include breakpoint(largeDesktop){
  #checkout-shipping-method-load{
    padding: 1rem 2rem;
  }
} /* END 1500px */
@include breakpoint(smallDesktop) {
  .opc{
    &-progress-bar{
      width: 100%;
      max-width: 100%;
      order: 1;
      padding-bottom:0;
    }
    &-wrapper {
      width: 100%;
      max-width: 100%;
      order: 3;
    }
    &-sidebar{
      width: 100%;
      max-width: 100%;
      order: 2;
      margin: 3rem 0;
      .modal-inner-wrap{
        max-width: 100%;
      }
    }
    &-block{
      &-summary, &-shipping-information{
        width: 100%;
        display: inline-block;
        vertical-align: top;
      }
      &-summary{
        margin-right: 1.5rem;
        margin-top:0 !important;
      }
    }
    &-progress-bar{
      padding-top: 0;
    }
  }
}/* END 1024px */
/*** RESPONSIVE ***/

@include breakpoint(tablet) {
  .opc{
    &-block{
      &-summary, &-shipping-information{
        max-width: 100%;
      }
      &-summary{
        margin-right: 0;
      }
    }
  }
  .shipping{
    &-address-item{
      width: 49%;
      &:nth-of-type(3n){
        margin-right: 2%;
      }
      &:nth-of-type(2n){
        margin-right: 0;
      }
    }
    &-information{
      margin-top: 4rem;
    }
  }
}/* END 768px */
@include breakpoint(phone) {
  .shipping{
    &-address-item{
      width: 100%;
      margin-right: 0;
      &:nth-of-type(3n){
        margin-right: 0;
      }
      &:nth-of-type(2n){
        margin-right: 0;
      }
    }
  }
  .opc {
    &-block {
      &-shipping {
        &-information {
          .action {
            width: auto;
          }
        }
      }
    }
    &-progress-bar{
      @include flex($wrap: true);
    }
  }
}/* END 480px */