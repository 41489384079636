input, select, textarea {
  @include fontDefault($fontButton , 1.6rem, $colorPrimary, normal);
  width: 100%;
  padding: 1rem 1.7rem;
  background: $colorWhite;
  border-radius: if($rounded-corners, 5px, null);

  background: $colorLighterGrey;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  @include border(0);
  &.checkbox{
    width: 1.3rem;
  }
  &[type="radio"]{
    padding: 0;
    font-size: 1.4rem;
    -webkit-appearance: radio;
  }
  &[type="checkbox"]{
    width: auto !important;
    font-size: 1.4rem;
    margin-right: .7rem;
    height: 1.3rem;
    -webkit-appearance: checkbox;
    margin-top: auto;
    margin-bottom: auto;
  }
  &[readonly=""]{
    cursor: not-allowed;
  }
  &::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder,&:-ms-input-placeholder, &:-moz-placeholder {
    color: $colorGrey;
  }
}
select{
  padding: 1rem 1.7rem;
  color: $colorPrimary;
  -webkit-appearance: auto;
  /*color: $colorLightGrey;*/
}

select#country {
  min-height: 3.6rem;
}
fieldset {
  @include border(none);
  display: block;
  br {
    display: none;
  }
}

img.captcha-img {
  height: 44px;
  margin-right: 1.5rem;
}

.account .choice {
  @include flex;
  flex-direction: row;
  margin-top: 2rem;
  span {
    display: block;

  }
}
.password-info {
  margin-bottom: 1.5rem;
  @include border(0);
  display: block;
  @include fontDefault(2, 2.6rem);
}
/*Contact*/
label{
  @include fontDefault(1.6, 3rem, $colorPrimary, normal);
}
form {
  .payment-option-inner{
    @extend .fieldset, .coupon;
  }
  .field{
    .control{
      margin: 1rem 0;
      text-align: left;
    }
    &set{
      padding-bottom: 1.5rem;
      &.coupon{
        input{
          background: $colorWhite;
        }
        &:after{
          display: none;
        }
      }
      &:after {
        margin: 3.5rem 0 0;
        content: attr(data-hasrequired);
        color: $colorError;
        font-size: 1.4rem;
        display: block;
      }
      .control {
        input, select {
          width: 100%;
        }
        input#captcha_user_login {
          text-transform: none;
        }
        textarea {
          width: 100%;
          padding: 1rem;
        }
      }
    }
  }
  .note {
    margin-bottom: 2rem;
    @include fontDefault(1.4, 3rem, $colorPrimary, normal);
  }
}

/*neue adresse tooltip checkout*/


.control{
  .privacypolicy &,
  .commercialpurpose &{
    @include flex($wrap: true);
    .label {
      width: calc(100% - 22px);
    }
  }
  &._with-tooltip{
    @include flex(space-between, $wrap:true);
    position: relative;
    .note{
      margin-top: 1rem;
    }
    .field-tooltip {
      cursor: pointer;
      @include overlay(0, 7px, null, 3.7rem, $right: true);
      @include flex(center, center);
      &.toggle{
        #tooltip {
          &:before {
            @include fontIcon(2);
            color: $colorSecondary;
            content: "";
          }
          &-label {
            @include border(0);
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }
        }
      }
      &._active{
        .field-tooltip-content {
          display: block;
        }
      }
      &-content{
        background: $colorWhite;
        @include border;
        @include fontDefault(1.4);
        padding: 1.2rem;
        display: none;
        @include overlay(50%, 3rem, 27rem, null, $right: true);
        text-transform: none;
        transform: translate(0, -50%);
        word-wrap: break-word;
        z-index: 2;
        max-width:calc(100vw - 6.7rem);
        @include boxShadow(0, .5, 1.5, 0, rgba(0,0,0,0.2))

      }
    }
    .input-text {
      -ms-flex-preferred-size: calc(100% - 4rem);
      flex-basis: calc(100% - 4rem);
    }
  }
}
fieldset{
  &.street {
    &.admin__control-fields {
      &.required {
        padding-bottom: 0;
        > .label {
          display: none;
          padding-bottom: 0;
        }
      }
    }
  }
}
#opc{
  &-new-shipping-address{
    .field{

      &.choice {
        width: 100%;
        @include flex;
        flex-direction: row;
        justify-content: flex-start;
        line-height: 1;
        align-items: center;
        label.label{
          line-height: 1;
        }
      }
    }
    #shipping-save-in-address-book{
      width: 25px;
      margin-bottom: 0;
    }
    input, select{
      width: 100%;
    }
  }
}

.required, ._required {
  &.checkout-agreement{
    > label {
      &:after{
        display: none;
      }
      span{
        &:after {
          @include fontDefault(1.7, normal, $colorPrimary);
          content: "*";
          margin-left: .5rem;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
  > label {
    position: relative;

    &:after {
      @include fontDefault(1.7, normal, $colorPrimary);
      content: "*";
      margin-left: .5rem;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.tocompare {
  &:before {
    content: "#{$iconCompare}";
    @include fontIcon;
    margin-right: 0.25rem;
  }
}
.field{
  margin-top: .3rem;
  &.region {
    display: none;
  }
}
.captcha {
  @include flex($wrap: true);
  padding: 1.7rem 2rem;
  @include border;
  label, .action{
    font-size: 1.4rem;
    &.reload{
      &:after{
        display: none;
      }
    }
  }
  .captcha {
    width: 100%;
    padding: 0;
    background: transparent;
    margin: 0;
    @include border(0);
    &-image {
      @include flex;
    }
  }
  @include breakpoint(phone){
    label, .action{
      font-size: 1.4rem;
    }
    .captcha {
      padding-top: 1rem;
      &-image {
        @include flex($wrap: true);
      }
    }
  }
}

.form{
  &-discount{
    display: none;
    .label{
      display: none;
    }
    .payment-option-inner{
      &:after{
        display: none;
      }
    }
    .action {
      width: 100%;
      &s-toolbar {
        > * {
          width: 100%;
        }
      }
    }
  }
}
