.webcrossing-widget-marquee-text{
    background-color: $colorTertiary;
    overflow:hidden;
    max-height:50px;
    padding-top: 0;

    .js-marquee-wrapper {
        .js-marquee {
            display:flex;
            justify-content: space-between;
            gap:10rem;
        }
    }

    .item {
        padding:1rem 0;
        display: inline-block;

        &.hidden {
            visibility: hidden;
        }

        a {
            color:$colorWhite;
            font-weight: 500;
            text-decoration: underline;
        }

        span {
            color:$colorWhite;
            font-weight: 500;
        }
    }
}