.webcrossing-widget-product-slider{
  .toolbar{
    overflow: hidden;
  }
  .splide {
    width: 100%;
    &__track {
      overflow: initial;
    }
  }
  .products {
    &.list{
      gap: 0;
      align-items: initial;
      flex-wrap: nowrap;
    }
  }
}
