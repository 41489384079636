.sidebar{
    width: 26rem;
    .account &{
        .block{
            .title{
                display: none;
            }
            &-customer-id{
                margin-bottom: 4rem;
                &-title{
                    display: block !important;
                    padding-bottom: 0;
                }
            }
        }
        &-main {
            li{
                &.current{
                    @include border(.3rem, $colorSecondary, $left: true);
                }
                > a,  > strong {
                    display: block;
                    padding: 0.5rem {
                        right: 1.8rem;
                        left: 1.5rem;
                    };
                    @include fontDefault(1.6, null, $colorPrimary);
                }
                > strong{
                    color: $colorSecondary;
                }
            }
            @include breakpoint(tablet){
                width: 100%;
                padding: {
                    bottom: 1.6rem;
                    top: 1.6rem;
                }
                margin-bottom: 3rem;
            }
            .account-nav {
                @include breakpoint(tablet){
                    padding: {
                        left: 0;
                        right: 0;
                    }
                }
                &-content{
                    padding:1.5rem 0 ;
                }
            }
        }
    }
    /*** RESPONSIVE ***/
    @include breakpoint(tablet){
        display:none;
    }
}