.webcrossing-widget-teaser-box{
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  &.one-third{
    .box {
      @include width($column:3);
      min-height: 44.8rem;
      &:first-child, &:last-child {
        @include width($column:3);
      }
      .overlay{
        h2{
          padding: 0 1rem;
        }
      }
    }
  }
  .block{
    &-title{
      position: relative;
      z-index: 1;
      padding: {
        right: 2rem;
        left: 2rem;
      };
    }
    &-content{
      position: relative;
      z-index: 1;
      .cms-home &{
        @include content(outer);
      }

    }
  }
  .action {
    margin-top: 5rem;
    &s {
      text-align: center;
    }
  }
  .box{
    @include flex($direction: column);
    overflow: hidden;
    position: relative;
    background: $colorSecondary;
    @include transition;
    min-height: 28.8rem;
    &:hover{
      .overlay{
        h2{

          color: $colorWhite;
        }
      }
      .description{
        padding-top: 2.5rem;
        max-height: 20rem;
      }
    }
    .image{
      text-align: center;
      line-height: 0;
      width: 100%;
      height: 100%;

      img{
        min-width: 100%;
      }

    }
    &:first-child{
      width: 67%;
    }
    &:last-child{
      width: 32%;
    }
    @include breakpoint(smallDesktop){
      flex-grow: 1;
      &:first-child{
        width: auto;
      }
      &:last-child{
        width: auto;
      }
    }
    &es{
      @include flex;
      gap: 3rem;

    }
  }
  .overlay{
    @include overlay;
    @include flex(centery, $wrap: true);
    @include transition;
    background: rgba($colorSecondary, .65);

    h2{
      @include fontDefault(4.3, normal, $colorWhite, normal, uppercase);
      text-align: center;
      @include transition;
    }
  }
  .description{
    text-align: center;
    overflow: hidden;
    @include transition;
    max-height: 0;
    padding: 0 2rem;
    max-width: 55rem;
    @include fontDefault(1.7rem, 3rem, $colorWhite, 300);

    p{

      line-height: 3rem;
    }
    a{
      display: inline-block;
      width: 100%;
      text-align: center;
      @include fontDefault($color: $colorWhite, $transform: uppercase);
    }
  }
  /*** RESPONSIVE ***/
  @include breakpoint(smallDesktop){

    &.one-third {
      .box {
        .overlay {
          h2 {
            font-size: 3rem;
          }
        }
      }
    }
    .box:hover{
      .description{
        max-height: initial;
      }
    }
  }
  @include breakpoint(tablet){
    &.one-third{
      .box {
        @include width($column:1);
        max-width: 70%;

        min-height: 30rem;
        &:first-child, &:last-child {
          @include width($column:3);
        }

        &es{
          @include flex(center, $wrap: true);
        }
      }
    }
    .box{
      width: 100%;
      .description{
        max-height: initial;
        padding-top: 2.5rem;
      }
      &es{
        @include flex($wrap: true);
      }
    }

  }/* End 768px */
  @include breakpoint(phone){
    &.one-third{
      .box {
        max-width: 100%;
      }
    }
    .block{
      &-content{
        .left{
          width: 100%;
          margin-bottom: 4rem;
        }
        .info{
          width: 100%;
        }
      }
    }
  }/* End 480px */
}