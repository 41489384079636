.webcrossing-widget-teaser-textlist{

  padding-top: 0;


  .block {
    &-title {
      @include flex($wrap: true);
      text-align: center;
      padding-top: 0;

      .headline {
        width: 100%;
        @include fontDefault(2, $weight: 300, $transform: uppercase);
        text-align: center;

      }

      h2, h3 {
        @include fontDefault(2.5, 3rem, $colorSecondary, normal, $transform: uppercase);
        max-width: 50rem;
        padding-bottom: 1rem;
        text-align: center;
      }

      h1 {
        text-align: center;
        @include fontDefault(4.2, 5rem, $weight: normal, $transform: uppercase);
        width: 100%;
        padding-bottom: 8rem;
        position: relative;

        .green {
          color: $colorSecondary;
        }

        &:after {
          @include overlay(4rem, 50%, 10rem, .2rem, $bottom: true);
          background: $colorSecondary;
          display: inline-block;
          transform: translate(-50%, 0);
          content: "";
        }
      }
    }
    &-content{
      padding-bottom:3rem;
      @include border($bottom: true);
      .go-to-link{
        width: 100%;
        @include fontDefault(null, null, $colorSecondary);
        display: block;
      }
      > .go-to-link{
        margin-top: 2rem;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
  .description{
    @include fontDefault(1.6, 2.4rem);
    @include content(inner);
  }
  .image{
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    @include content(inner);
    img{
      max-width: 35rem;
      margin-bottom: 2rem;
    }
    &-caption{
      @include fontDefault(1, 1.1, $weight: bold);
    }
  }
  .action {
    @include fontDefault($transform: uppercase);
    &s {
      flex-shrink: 0;
    }
  }
  @include breakpoint(smallDesktop){
    .block-title{
      h1{
        margin-bottom: 0;
      }
    }
    &.social-media{
      .action {
        width: 100%;
        margin-left: 0;
        &+ .action{
          margin-top: 1rem;
        }
        &s {
          @include flex($wrap: true);
          gap: 0;
        }
      }
    }
    &.newsletter {
      .block-title {
        margin-right: 0;
        padding-right: 2rem;
      }
      .description{
        padding-right: 2rem;
      }
    }
    &.service{
      .description-additional{
        @include flex($wrap: true);
      }
    }
  }
  @include breakpoint(tablet){
    main &{
      .block {
        &-title{
          padding: 0;
          @include border(0);
          width: 100%;
          margin: 0 {
            bottom: 1.5rem;
          };
          h2{
            @include flex(centery);
            padding: 0 {
              bottom: 1.5rem;
            };
          }
        }
        &-content{
          @include flex(center, $wrap: true);
        }
      }
      .description{
        padding: 0;
        width: 100%;
        margin-bottom: 1.3rem;
        text-align: center;
      }
    }
    &.newsletter{
      .block {
        &-title {
          &:after{
            display: none;
          }
          h2 {
            &:after{
              display: none;
            }
          }
        }
      }
    }
    .block {
      &-parent {
        @include flex($wrap: true);
      }
    }
  }
  @include breakpoint(phone){
    &.service{
      .description-additional{
        @include flex($wrap: true);;
      }
    }
    &.newsletter .block{
      &-title h2{
        @include flex($wrap: true);
        &:before{
          width: 100%;
          margin-right: 0;
        }
      }
      &-content .description-additional .subscribe {
        @include flex($wrap: true);
        width: 100%;
        .actions {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }
    &.social-media{
      .description{
        max-width: 100%;
        &-additional{
          width: 100%;
          max-width: 100%;
          ul{
            padding-top: 3rem;
            @include flex(centery);
            gap: 3rem
          }
        }
      }
    }
    &.flowing-text{
      .description-additional{
        gap:0;
        @include flex($direction: column);
      }
    }
  }
}