/*Modal*/
body._has-modal {
  height: 100%;
  width: 100%;
  .header-main{
    z-index: 1;
  }
}
#opc-new-shipping-address{
  width: 100%;
  > form{
    padding-right: 2rem;
  }
}
.modal {
  &s-wrapper{
    .modal{
      &-inner-wrap{
        background: $colorWhite;
        max-height: 97vh;
        @include content(outer);
        width: auto;
        padding: 3.2rem;
        max-width: 90%;
      }
      &-content{
        overflow-y: auto;
        max-height: calc(97vh - 30rem);
        h2{
          @include fontDefault(5.3, 6.3rem,$weight: 600);
        }
        h3{
          @include fontDefault(2.7, 3.2rem, $weight: 600);
          margin: {
            top: 3.5rem;
            bottom: 2rem;
          }
        }
      }
    }
  }
  &-popup{
    @include overlay($position: fixed);
    visibility: hidden;
    background: rgba($colorBlack, .7);
    &._show{
      @include flex(centery);
      z-index: 20 !important;
      visibility: visible;
    }
    &.popup-authentication{
      .modal-inner-wrap{
        width: $mediumContent;
      }
    }
  }
  &-header{
    position: relative;
    min-height: 5rem;
    .action-close{
      @include overlay(0, right, null, null);
      cursor: pointer;
      @include fontDefault(0);
      font-size: 0;
      @include border(0);
      background: transparent;;
      &:before{
        content: $iconClose;
        display: inline-block;
        text-align: center;
        @include fontIcon(2, normal, $colorPrimary);
        border-radius: if($rounded-corners, 50px, null);
        height: 2.3rem;
        width: 2.3rem;
        @include flex(centery, $flex: false);
        margin: 0 auto;
      }
    }
  }
  &-content{
    .block{
      &-authentication{
        padding-right: 2rem;
        width: 100%;
        @include flex($gap: 2);
        .block{
          width: 100%;
        }
      }
      &-title{
        @include flex(flex-start);
      }
      &-content{
        ul{
          margin-bottom: 1rem;
          padding-left: 2rem;
        }
        li{
          list-style: disc;
        }
      }
    }
  }
  &-footer{
    @include flex;
    margin-top:2rem;
  }
} /* Modal */
.fieldset[disabled]{
  .modal{
    &-costum, &-popup, &-slide{
      .action-close{
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
      }
    }
  }
}
.webcrossing-popup-1{
  .modal {
    &-inner-wrap {
      padding: 0;
    }
    &-header{
      @include overlay(3.7rem, 3.7rem, null, null, $right: true);
    }
    &-footer{
      display: none;
    }
    &-content{
      .newsletterPopup{
        @include flex;
        .image{
          background: no-repeat center center;
          background-size: cover;
          max-width: 50%;
          width: 100%;
          position: relative;
          line-height: 0;
          .new{
            @include overlay(2rem, left);
            background: $colorPrimary;
            padding: 1.3rem {
              left: 1.5rem;
              right: 1.7rem;
            }
            @include fontDefault(2.1, 2.1rem, $colorWhite, bold, uppercase, italic);
          }
          img{
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
        .text{
          width: 50%;
          @include flex(centery);
          padding: 3.7rem;
          &Container{
            h2{
              text-align: left;
              @include fontDefault(4, null, $colorPrimary, null, normal);
              padding-top: 0;
            }
            .note{
              padding-top: 7rem;
              font-weight: 700;
              span{
                font-size: 3rem;
                color: $colorRed;
              }
            }
            .inputfield{
              .title{
                display: none;
              }
              .content{
                .label{
                  display: none;
                }
              }
              form{
                @include flex;
                padding-top: 1rem;
              }
              input{
                width: 100%;
              }
              button{
                width: 10.6rem;
                height: 4.6rem;
                background: $colorPrimary;
                color: $colorWhite;
                border-color: $colorPrimary;
                padding: 0;
                border-radius: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
.ie10 .modal-popup._inner-scroll, .ie9 .modal-popup._inner-scroll {
  overflow-y: auto;
}
.ie10 .modal-popup._inner-scroll .modal-inner-wrap, .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
  max-height: none;
}
div#newsletter-error {
  padding: 10px;
}
body._has-modal-custom .modal-custom-overlay {
  @include overlay(0,0,100vw,100vh, fixed, $z-index: 899);
}
/*Modal Ende*/

.mp {
  &_modal{
    @include overlay(null,null,100%,100%, fixed, $z-index: 9999);
    background: rgba($colorSecondary, .8);
    display: none;
  }
  &_popupquote {
    &-content {
      @include overlay(50%,50%,85%,null);
      transform: translate(-50%, -50%);
      max-width: 80rem;
      max-height: 90vh;
      background: $colorWhite;
      padding: 5rem 3rem;
      .close{
        @include overlay(2rem,2rem, null,null, $right: true);
        cursor: pointer;
        font-size: 0;
        &:before{
        }
      }
      .actions-toolbar{
        margin-top: 2rem;
      }
      > form{
        overflow-y: auto;
        max-height: calc(90vh - 10rem);
      }
      .terms_condition{
        @include flex;
        input{
          width: auto;
          margin-right: 1rem;
        }
        .label{
          width: 100%;
          &:after{
            position: relative;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
}
@include breakpoint(tablet){
  .modal {
    &s-wrapper .modal-inner-wrap {
      width: 80%;
    }
    &-content .block-authentication{
      @include flex($direction: column);
    }
  }
}
@include breakpoint(phone){
  .modal{
    &-footer{
      @include flex($wrap: true);
    }
  }
}
