@media not all and (min-resolution:.001dpcm) {
    .account, .customer-account-create {
        .customer-container {
            form {
                fieldset {
                    margin-right: 3rem;
                    &:nth-of-type(2n){
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .account .customer-container {
        .block, .toolbar{
            margin-top: 3rem;
        }
        .block {
            &:first-child{
                margin-top: 0;
            }
            &-content .box {
                margin: {
                    right: 3rem;
                    bottom: 3rem;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }

    .product-item{
        margin-right: 3rem;
        &:nth-of-type(4n){
            margin-right: 0;
        }
    }
    .customer-container{
        .block{
            margin-right: 3rem;
            &:last-child{
                margin-right: 0;
            }
        }
    }

    .minicart-items{
        .product-item .product{
            &-item-details{
                padding-left: 1rem;
            }
        }
    }
    @supports (-webkit-appearance:none) {

        @include breakpoint(desktop){
            .product-item{

                &:nth-of-type(4n){
                    margin-right: 3rem;
                }
                &:nth-of-type(3n){
                    margin-right: 0;
                }
            }
        }
        @include breakpoint(smallDesktop){
            .product-item{

                &:nth-of-type(3n){
                    margin-right: 3rem;
                }
                &:nth-of-type(2n){
                    margin-right: 0;
                }
            }
            .footer-payment ul li {
                &:last-child{
                    margin-top: 1rem;
                }
            }
        }
        @include breakpoint(tablet){
            .product-item{
                margin-right: 0;
                &:nth-of-type(3n){
                    margin-right: 0;
                }
            }
        }
    }
}