.box-tocart {
  .fieldset {
    padding-bottom: 0;
    width: 100%;
    @include flex(flex-end);
    margin-top: 2rem;
    input, select{
      width: auto;
      margin-bottom: 2rem;
    }
    .input-text.qty {
      width: auto;
      max-width: 10rem;
      margin: 0;
      padding: {
        top: 1.2rem;
        bottom: 1.2rem;
      };
    }
    .field.qty {
      margin: {
        right: 1rem;
        top: 0;
      }
      width: auto;
      label {
        width: 100%;
        @extend .price-final_price;
        display: none;
      }
      .control {
        height: 100%;
        margin:0;
      }
    }
  }
  @include breakpoint(phone){
    width: 100%;
    margin-bottom: 2rem;
    .fieldset {
      @include flex($wrap: true);
      width: 100%;
      .field{
        &.qty{
          margin: {
            top: 0;
          }
        }
      }
      .actions {
        margin-left: 0;
      }
    }
  }/* END 480px */
}
.box-onrequest {
  .request-details {
    text-align: right;
    span {
      margin-top: 3rem;
      @include fontDefault(1.5, 2rem, $colorSecondary);
      display: inline-block;
    }
  }
}
.tax-details{
  margin-top: 2.5rem;
  text-align: right;
  font-size: 1.4rem;
  a{
    color: $colorSecondary;
  }
}
.bulk-details{
  text-align: right;
  font-size: 1.4rem;
  a{
    color: $colorSecondary;
  }
}
.products, .categories{
  &.grid {
    .product, .category{
      &-item{
        margin-bottom: 2.6rem;
        position: relative;
        z-index: 1;

        @include transition;
        text-align: left;
        padding: 1rem;
        @include width($column: 4);
        @include border($color: $colorWhite);
        @include boxShadow(0, .1, .5, 0, rgba($colorBlack, .12));

        &:hover{
          @include boxShadow(0, .1, .5, 0, $colorTertiary);
          @include border($color: $colorTertiary)
        }
        &-content{
          padding: 1.5rem;
          height: 20rem;
          @include transition;
          overflow: hidden;
          &:after{
            @include overlay(9rem,0,100%, 30%, $bottom: true);
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#303030+0,303030+100&0.02+0,1+100 */
            background: -moz-linear-gradient(top,  rgba(48,48,48,0.02) 0%, rgba(48,48,48,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(48,48,48,0.02) 0%,rgba(48,48,48,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(48,48,48,0.02) 0%,rgba(48,48,48,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05303030', endColorstr='#303030',GradientType=0 ); /* IE6-9 */
            content: "";
            @include transition;
          }
          &.active{
            &:after{
              height: 0;
            }
          }
        }
        &-categorylink{
          a{
            padding: 1rem;
          }
        }
        &-name{
          padding: 2rem 0 0 0;

          h3{
            padding: 0;
            margin: 0;
            text-align: center;
            @include fontDefault(1.4, normal, $colorTitleDefault, 500);
          }
          a{
            @include fontDefault(2.85, normal, $colorSecondary, bold);
          }
        }
        &-sku {
          padding: 0 0 2rem 0;

          span {
            text-align: center;
            letter-spacing: 1.2px;
            @include fontDefault(1.2, normal, $colorTitleDefault, 500);
          }
        }
      }
      @include breakpoint(largeDesktop){
        &-item{
          @include width($column: 3);
        }
      }
      @include breakpoint(smallDesktop){
        &-item{
          @include width($column: 2);
        }
      }
      @include breakpoint(phone){
        &-item{
          @include width($column: 1);
        }
        &-options{
          &-bottom{
            @include flex($wrap: true);
          }
        }
      }/* END 720px */
    }
  }
  &.list {
    /*width: 100%;*/
    min-width: 100%;
    margin: {
      top: 5rem;
      bottom: 5rem;
    }
    .product{
      &-item {
        margin: 1rem 0;
        &-photo {
          padding: 0;

          img {
            top: 50%;
            transform: translate(-50%, -50%);
          }

        }
      }
    }
    .product, .category{
      &-item {
        &-photo{
          width: auto;
        }
      }
    }
  }

}
.product, .category {
  /* Product List Page */
  &-item {
    img{
      width: 100%;
    }

    &-photo{
      width: 100%;
      display: inline-block;
      text-align: center;
      transform: translate(0);
      line-height: 0;
      padding: 2rem 0;
      max-width: 100%;
      img{
        max-width: 100%;
      }
      span {
        display: inline-block;
        width: 100%;
        line-height: 0;
        max-width: 100%;
      }
    }
    &-link{
      display: inline-block;
      width: 100%;
      text-align: center;
      &.link{
        margin-top: 1rem;
        opacity: 0;
        transform: scale(0);
        > a{
          @include fontDefault(1.7, null, $colorPrimary, bold);
        }

      }
    }
    &s{
      @include flex($justify: flex-start, $align: stretch, $wrap: true, $gap: true);
    }

  }/* End .product-item, .product-info */
  &-image{
    &-container{
      position: relative;
      display: block;
      width: 100%;
    }
    &-photo{
      @include overlay(top, 50%, null, null);
      transform: translate(-50%, 0);
    }
  }/* End .product-image */
  /* Product Detail Page */
  &-general-header-inner{
    font-weight: bold;
  }
  &-info{
    &-description{
      margin-bottom: 2rem;
      p{
        @include fontDefault(1.4,null, $colorTextLight);
      }
    }
    &-media {
      position: relative;
      max-width: 48rem;
      width: 100%;
      max-height: 100%;
      z-index: 2;
      .action-skip-wrapper{
        display: none;
      }
      .giftcard {
        &-template {
          &-setting{
            display: none;
          }
        }
      }
    }/* End -media */
    &-main {
      width: 100%;
      @include fontDefault(1.6, 2.6rem);
      @include flex($direction:column);
      font-weight: normal;
      position: relative;
      z-index: 1;
    }/* End -media */
    &-price {
      order:0;
      @include flex($direction:column, $wrap: true);

      .sale-price{
        order: 1;
      }
      .price {

        &-box {
          @include flex(flex-endy, $direction: row-reverse, $wrap: true);
        }
        &-final_price{
          text-align: right;
          @include flex(flex-start, null);
          .price{
            @include fontDefault(2, normal, null, 600);
          }

        }
      }
    }
    &-buttons{
      @include flex($wrap: true, $gap: 1, $direction: column);
      width: 100%;
      max-width: 26rem;
      margin-bottom: 5rem;
      .action{
        background: transparent;
        @include border(1px, $colorPrimary);
        @include flex(flex-start);
        @include fontDefault(null, 2.2rem, $colorPrimary, 500 );
        padding: 1.2rem 3rem;

        &:after{
          color: $colorPrimary;
          width: 2.2rem;
          text-align: center;
        }
        &:hover{
          background: $colorSecondary;
          color: $colorWhite;
        }

        i {
          font-size: 1.5rem;
          margin-right: 1rem;
        }
      }
    }
    &-availability{
      @include fontDefault(1.4, normal);
      margin-left: 1rem;
      padding-left: 1rem;
      @include border($left: true);
      @include flex(flex-start, center);
      .icon{
        margin-right: 1rem;
        @include border(0);
      }

    }
    &-container{
      @include flex($gap: 3);
    }
    &-header {
      margin-bottom: 4rem;
      &-inner {
        @include flex(flex-start, center);
      }
    }
    &-body{
      @include flex($gap: 3);
    }
    &-description,
    &-short-description{
      margin-bottom: 5rem;
      width: 100%;
      .description-attributes {
        display: table;
        width:100%;
        border-collapse: collapse;
        margin-bottom: 1rem;

          &.description {
              -webkit-box-pack: justify;
              display: grid;
              grid-template-columns: repeat(auto-fill,minmax(38rem,1fr));
              justify-content: space-between;
              row-gap: 2rem;
              column-gap: 2rem;

              .attribute {
                background: #f5f5f5;
                padding: 1rem;

                 span {
                     display: table-cell;

                     &.label {
                         display: block;
                         font-weight: 700;
                         padding:0;
                     }

                     &.value {
                         padding:1rem 0 0 0;
                     }
                 }
              }
          }

        .attribute {
          display: table-row;
          border-bottom: 1px solid #f0f0f0;
          span {
            display:table-cell;
            &.label {
              @include fontDefault(1.5, normal, $weight: normal);
              color: $colorPrimary;
              padding: 1rem 2rem 1rem 0;
              min-width: 10rem;
            }
            &.value {
              @include fontDefault(1.5, normal, $weight: normal);
              color: $colorPrimary;
              padding: 1rem 0 1rem 2rem;
            }
          }
        }
      }
    }
    &-short-itemdescription{
      margin-bottom: 5rem;
      width: 100%;
    }
    &-hint{
      margin-top: 3rem;
      padding-left: 20%;
      @include fontDefault(1.5, 2rem, $colorGrey);
      text-align: right;
    }
  }/* End .product-info */
  &-add {
    &-form {
      .giftcard {
        &-template {
          color: $colorWhite;
          &-preview {
            max-width: 8rem;
            display: none;
            > div {
              max-width: 100%;
            }
          }
          &-setting {
            padding-bottom: 5rem;
            .block-title {
              margin-bottom: 1.4rem;
            }
            &-select {
              > div {
                @include flex;
              }
            }
          }
        }
        &-design {
          color: $colorWhite;
          &-button {
            color: $colorWhite;
            &-container {
              margin-right: 1.2rem;
              max-width: 8rem;
              i {
                line-height: normal;
              }
              img {
                @include border(2px, $colorWhite);
              }
              span {
                width: 100%;
                display: block;
                @include fontDefault(1.4, normal, $colorWhite);
                padding: 1.5rem;
                @include border($color: $colorWhite);
              }
              button {
                @include border(0);
                background: transparent;
                @include fontDefault;
              }
              &.active {
                img {
                  @include border(2px, $colorPrimary);
                }
              }
            }
          }
        }
      }
    }
    /* End -add-form */
  }/* End .product-add */
  &-options{
    &-bottom{
      margin: {
        top: 3rem;
        bottom: 2.5rem;
      }
      padding-bottom: 2.5rem;
      @include border($color: $colorSwatchGrey, $bottom: true);
      @include flex(flex-start, center);
    }
  }
  &.detailed {
    @include flex;
    width: 100%;
    padding: 0 0 4.5rem 0;
    margin-top: 5rem;
    .item {
      &s{
        position: relative;
        width: 100%;
      }
      &.title {
        float: left;
        width: auto;
        padding-bottom: 0;
        margin-right: .5rem;
        @include fontDefault(1.8);
        a {
          display: block;
          z-index: 2;
          cursor: pointer;
          @include fontDefault(1.8, $weight: 500);
          padding: 1.5rem 3rem;
          text-align: center;
          background: $colorLightGrey;
        }
        &.active {
          a {
            color: $colorWhite;
            background: $colorPrimary;
          }
          &+.content .inner-content {
            opacity: 1;
          }
        }
        &.active + .content{
          display: block;
        }
      }

      &.content {
        float: right;
        margin-left: -100%;
        width: 100%;
        padding: 2.2rem 0;
        margin-top: 5.5rem;
        @include border($top: true);
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
  /* END */
  &-price-container{
    @include flex(space-between, center);
    padding: 2rem 1.5rem 1rem;
    @include overlay(0,0,100%, auto, $bottom: true, $z-index: 2);
    .list &{
      padding-top: 1rem;
      background: $colorGrey;
      @include border($bottom: true, $color: $colorSecondary);
      &:before{
        content: "";
        @include overlay(-4rem, 0, 100%, 4rem);
      }
    }
  }
  .block-viewed-products-grid &{
    &-item{
      width: 100%;
      @include border($bottom: true);
      padding: {
        top: 2rem;
        bottom: 2rem;
      }
      &s{
        gap:0;
      }
      &-info{
        @include flex;
        position: relative;
        min-height: 7.4rem;


      }
      &-link{
        @include fontDefault(0);
        width: auto;
        display: inline-block;
        &:after{
          content: $iconEye;
          @include fontIcon(1.5, normal, $color: $colorWhite);
          padding: .5rem 1rem;
          background: rgba($colorBlack, .2);
          display: inline-block;
          @include transition;
        }
        &:hover{
          &:after{

            background: rgba($colorBlack, 1);
          }
        }
      }
      &-photo{
        width: auto;
        max-width: 5rem;
        padding: 0;
      }
      &-name{
        @include fontDefault(1.4);

      }
      &-details{
        @include flex;
        padding: 0 5rem 0 1rem;
        width: 100%;
        .price-box{
          display: none;
        }

      }
      &-description{
        @include overlay(4rem, $width: auto, $height: auto, $right: true)
      }
      &-actions{
        @include overlay($width: auto, $height: auto, $right: true);
        .action{
          @include fontDefault(1.4, $color: $colorWhite);
          padding: .7rem;
          min-width: initial;
          width: 100%;
          &:after{
            margin-left: 0;
            content: "";
            background: no-repeat center center $iconCartWhite;
            background-size: contain;
            width: 2.1rem;
            height: 2rem;
          }

        }
        span{
          display: none;
        }
      }
    }
  }
  /*** RESPONSIVE ***/
  @include breakpoint(largeDesktop){
    &-item{
      .grid &{
        @include width($column: 3);
      }
    }
  }
  @include breakpoint(smallDesktop){
    &-info{
      &-media{
        max-width: 30rem;
      }
      &-short-itemdescription{
        margin-bottom: 0;
      }
      &-buttons{
        margin-top: 0;
        width: 100%;
        max-width: none;
      }
      &-body{
        @include flex($wrap: true);
      }
      &-price{
        margin-top: 2rem;
      }
      &-hint{
        padding-right: 2rem !important;
      }
    }
  }
  @include breakpoint(tablet){
    &.detailed{
      .item.title a{
        @include fontDefault(1.4);
      }
    }
    &-info {
      &-container{
       @include flex($wrap: true);
      }
      &-media{
       max-width: 100%;
      }
    }
  }
  @include breakpoint(phone){
    &-info {
      &-availability{
        padding: 0;
        margin: 0;
        @include border(0);
      }
      &-header-inner {
        @include flex($wrap: true);

        > div {
          margin-top: 1rem;
          width: 100%;

        }
      }
      &-hint{
        padding-right: 0 !important;
      }
      &-description,
      &-short-description {
        .description-attributes {
            &.description {
                grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
            }

          .attribute {
            display: block;

            span {
              display: block;
              width: 100%;

              &.label {
                padding: 0.5rem 0;
                text-decoration:underline;
              }

              &.value {
                padding: 0.5rem 0;
              }
            }
          }
        }
      }
    }
    &.detailed .item {
      &.title{
        margin-right: 0;
        width: 100%;
      }
      &.content {
        margin: {
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.product-item-variants {
  .container {
    margin-top: 2rem;
    padding:0;

    ul {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;

      li {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
}


