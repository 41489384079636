#store.settings{
  display: none;
}
.navigation{
  &-header, &-footer{
    display: none;
  }
  > ul{
    @include flex(null, center);
    width: 100%;
    li{
      position: relative;
      &.parent{
        > a{
          @include flex(space-between, center);
          width: 100%;
          &:after{
            @include fontIcon(1.7, 500);
            content: $iconArrowNext;
            padding-left: 2rem;
          }
        }
      }
      a {
        padding: 1rem 2rem;
        position: relative;
        @include fontDefault(1.7, normal, $colorFontDefault, normal, uppercase);
        @include transition;
        display: block;
        span{
          white-space: nowrap;
        }
        &:hover{
          color: $colorSecondary;
        }
        &:after{
          color:$colorWhite;
        }
      }

      &.level0 {
        > a{
          border: 1px solid $colorPrimary;
          &:hover{
            background: $colorSecondary;
            color: $colorWhite;
            border: 1px solid $colorSecondary;
          }
        }
        &.first {
          li {
            width: 100%;
          }
          /*span {
            @include fontDefault(1.4, normal, $colorWhite, normal, uppercase);
          }*/
          > a{
            @include fontDefault(null, null, $colorWhite, $weight: 500);
            //padding: 1.3rem 2rem;
            background: $colorSecondary;
            border: 1px solid $colorSecondary;
            &:after{
              @include fontIcon(1.7, 500);
              content: $iconMenu;
              margin-left: 4rem;
              transform: rotate(0);
            }
          }
          &:hover{
            background: $colorButton;
            > ul{
              @include flex;
              max-height: calc(100vh - 16.5rem);
            }
          }
          .submenu{
            a{
              @include fontDefault(null, null, $colorWhite, $weight: 400);
            }
          }
          &.level1{
            &:last-child{
              padding-right: 0;
            }
          }
          &.nav-1-8{
            background: $colorTertiary;
            a{
              position: relative;
              &:before{
                @include overlay(0, -1rem, 1rem, 100%);
                background: $colorTertiary;
                content: "";
              }
            }
            &:after{
              @include overlay(-.8rem, -1.7rem, 1.5rem, 1.5rem, $bottom: true, $z-index: -2);
              content: "";
              border-left: 1rem solid transparent;
              border-right: 1rem solid transparent;
              transform: rotate(90deg);
              border-top: 1rem solid #FFC774;
            }
          }
        }
      }
    }
    > li{
      > ul {
        display: none;
        @include overlay(100%, left, auto, auto);
        z-index: 2;
        padding: 0;
        width: 100%;
        background: $colorSecondary;
        li {
          //background: transparent;
          &:hover {
            background: $colorButton;
          }
        }
      }
    }
  }
  ul{
    @include flex($wrap: true, $gap: 0);
    ul{
      display: none;
      gap: 0;
      @include overlay(0, 100%, auto, auto);
      padding-left: .5rem;
      background: transparent;
      li{
        background: $colorSecondary;
        a{
          font-size: 1.7rem;
          font-weight: normal;
        }
      }
    }
    > li:hover{
      > ul{
        display: block;
      }
    }
  }

  a,  li{
    word-break: normal;
    hyphens: none;
  }
}
.nav-toggle{
  display: none;
}
.menu-cms-block{
  a{
    position: relative;
  }
}
@include breakpoint(desktop){

}
/*** RESPONSIVE ***/
