.account{
  .message + .actions-toolbar{
    margin-top: 1rem;
  }
  p.toolbar-amount{
    display: none;
  }
}
.customer-container{
  @include flex(space-between, flex-start, $gap: 3);
  padding: 5rem 0;
  .account &{
    padding:  {
      top: 0;
    }
    @include flex($wrap: true);
    .block {
      &-content {
        @include flex(flex-starty, $wrap: true);
        gap: 3rem;
        .box {
          width: calc(33% - 3rem);
          padding: 2rem {
            right: 6rem;
          };
          @include border(1px, $colorSwatchGrey);
          position: relative;
          @include fontDefault(1.6);
          &-actions {
            @include overlay(2rem, 1rem, null, null, $right: true);
            @include flex(space-between, flex-start);
          }
          &-title {
            display: block;
            margin-bottom: 1rem;
            word-break: break-word;
            hyphens: auto;
          }
          &-content {
            .payment-method {
              padding-top: 0;
              .title {
                color: $colorSecondary;
              }
            }
          }
        }
      }
      &-order-details-view{
        .box{
          padding-right: 2rem;
        }
      }
    }
  }
  .actions-toolbar{
    margin-top: 1.5rem;
  }
  .block, .ordered{
    width: 100%;
    &-title{
      text-align: left;
      padding: {
        top: 0;
        bottom: 1rem;
      }
      margin-bottom: 2rem;
      @include flex(flex-start);
      @include border($color: $colorSwatchGrey, $bottom: true);
      @include fontDefault(1.8, 2.7rem);
      strong{
        @include fontDefault(null, null, $weight: 600);
      }
    }
  }
  form{
    @include flex(flex-start, $wrap: true, $gap: 3);
    width: 100%;
    &.form-edit-account{
      display: block;
      .fieldset{
        &.info{
          float: left;
          padding-bottom: 0;
        }
        &.password{
          float: right;
        }
        &.additional_info{
          clear: both;
          .col-mp{
            @include flex(flex-start, flex-start);
            input{
              margin-top: .8rem;
            }
          }
        }
        &:after{
          display: none;
        }
      }
    }
    .fieldset, .fieldset-fullname .fields{
      .label[for="firstname"]{
       /* display: none;*/
      }
      .fieldset{
        width: 100%;
        padding: 0;
      }
      > .field{
        margin-bottom: 2rem;
        &.fullname{
          >.control{
            margin-top: 0;
          }
        }
      }
    }
    .hint{
      display: block;
      width: 100%;
      margin-top: 1rem;
      @include fontDefault(1.2, null, $colorSecondary);
    }
    .actions-toolbar{
      order:2;
      width: 100%;
    }
    .legend, .label{
      @extend .block-title;
      width: 100%;
      @include fontDefault(1.8, 2.7rem, $weight: 600);
    }
    .street{
      .field.primary{
        > .label{
          display: none;
        }
      }
    }
    .label{
      @include border(0);
      margin-bottom: 0;
      padding-bottom: 0;
    }
    fieldset{
      width: calc(50% - 1.5rem);
      &:empty{
        display: none;
      }
      &.create, &.additional_info{
        &:after{
          display: none;
        }
      }
      &.additional_info{
        padding-bottom: 0;
      }
      &.login{
        width: 100%;
      }
    }
  }
  @include breakpoint(desktop){
    @include flex($wrap: true);
    .account &{
      .block-content{
        .box{
          width: 100%;
        }
      }
    }
  }
  @include breakpoint(smallDesktop){
    form{
      @include flex($wrap: true);
      fieldset{
        width: 100%;
      }
    }
  }
  @include breakpoint(tablet){
    padding-bottom: 3rem;
  }
  @include breakpoint(phone){
    .account &{
      .block-content{
        .box{
          width: 100%;
        }
      }
    }
  }
}
.order {
  &-title{
    @extend .block-title;
  }
  &-products-toolbar {
    @include fontDefault(1.4, 2rem, $weight: bold);
    text-align: right;
    margin: 0;
    select {
      @include fontDefault(1.4, 2rem, $weight: bold);
      text-align: right;
    }
  }
  &s-history{
    width: 100%;
  }
}
.limiter {
  margin: {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  @include flex(null, center);
  padding: {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  select#limiter {
    width: 7.5rem;
    margin: {
      left: 0.5rem;
      right: 1rem;
    }
  }
}