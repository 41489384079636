.title{
  @include fontDefault(2, normal, $weight: 600);
  padding-bottom: 1.6rem;
  display: block;
}
.callforprice-action{
  .price-details{
    display: none;
  }
}
.block-content {
  > .description {
    ul {
      list-style-type: initial;
      list-style-position: inside;
    }
  }
}
.block-title{
  @include flex(centery, $wrap:wrap);
  h2{
    width: 100%;
    text-align: center;
    @include fontDefault(3, 6rem, $colorFontDefault, 400);
    padding-top: 3rem !important;
    padding-bottom: 6rem;
    border-top: 1px solid $colorBorder;
  }
}
.splide{
  max-width: 100%;
}
.block-viewed-products-grid{
  padding: 0;
  text-align: left;
  .block{
    &-title{
      @include flex(flex-start);
      @include fontDefault(2, normal, $transform: uppercase);
      padding-bottom: 2rem;
    }
  }
}
.footer-bottom-hotline{
  @include overlay(50%, 8.2rem, auto, auto, fixed, $right: true, $z-index: 2);
  transform: translate(100%, 0);
  display: block;
  @include transition();
  background: $colorSecondary;
  -webkit-border-top-left-radius: .7rem;
-webkit-border-bottom-left-radius: .7rem;
-moz-border-radius-topleft: .7rem;
-moz-border-radius-bottomleft: .7rem;
border-top-left-radius: .7rem;
border-bottom-left-radius: .7rem;

  &:hover{
    max-width: 100%;
    transform: translate(0, 0);
    right: 0;
    .hidden{
      max-height: 20rem;
      opacity: 1;
    }
  }
  &.section .inner-container{
    height: auto;
    gap: 0;
    @include flex(flex-start);
    .title{
      @include border(0);
      line-height: 0;
      padding-bottom: 1.7rem;
    }
  }
  .title{
    width: auto;
    padding: 2rem;
  }
  li{
    @include fontDefault(1.9, normal, $colorWhite);
    &.languages{
      position: relative;
      padding: .4rem 0 .4rem 3rem;
      &:before, &:after{
        @include overlay(0,0,auto, auto);
        line-height: 0;
      }
      &:before{
        content: url($iconDir + "at.svg");
      }
      &:after{
        content: url($iconDir + "de.svg");
        top: 2rem;
      }
    }
    &.mail{
      padding-top: 2rem;
      a{
        @include flex(flex-start, center);
        &:before{
          content: $iconLetter;
          @include fontIcon(1.4, $color: $colorWhite);
          margin-right: 1rem;
        }
      }
    }
  }
  h3{
    @include fontDefault(1.8, normal, $colorWhite, $transform: none);
    margin-bottom: 2.6rem;
  }
  .hidden{
    padding: 2rem 2rem 2rem 0;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    @include transition();
  }
}
.to-top{
  z-index: 9;
  @include overlay(3rem, -10rem, 5rem, 5rem, fixed, $right: true, $bottom: true);
  background: $colorSecondary;
  @include fontDefault(5, 5rem, $colorWhite, 300);
  @include flex(centery);
  @include transition;
  cursor: pointer;
  &.active{
    right: 0;
  }
}

.main div.ovvvport{
  z-index: 1;
}
/**** Columns *****/
.page {
  &s {
    width: 100%;
    > strong {
      display: none;
    }
    .products-grid + .toolbar-products &{
      display: block;
    }
    ul {
      @include flex(centery);
      padding: 2rem;

      li {
        @include flex(centery);

        &.item {
          margin: 0 1rem;

          a, strong {
            @include fontDefault(1.5, null, $weight: normal);
            padding: .5rem 1rem;
            text-align: center;
            @include flex(null, center);
            @include border(0);

            span {
              display: inline-block;
              width: 100%;
              text-align: center;
            }
          }

          .label {
            display: none;
          }
        }

        &.pages {
          &-item {
            &-previous, &-next {
              a {
                &:before {
                  content: "#{$iconPagination}";
                  @include fontIcon(1.5);
                  text-align: center;
                  display: inline-block;
                  width: 100%;
                }
              }
            }

            &-previous {
              a {
                &:before {
                  transform: rotate(180deg);
                }

                span {
                  display: none;
                }
              }
            }

            &-next {
              a {
                span {
                  display: none;
                }
              }
            }
          }
        }

        &.current {
          strong {
            background: $colorPrimary;
            color: $colorWhite;
          }
        }
      }
    }
  }
  &-print{
    .logo{
      @include content(outer);
      display: block;
      padding: {
        top: 2rem;
        bottom: 2rem;
      }
    }
    .order-title, .block-title{
      @include flex(flex-start);
      text-transform: uppercase;

    }
    .actions-toolbar{
      display: none;
    }
    .block-order-details-view{
      margin-top: 3rem;
      .block-content{
        @include flex($wrap: true, $gap: 2);
      }
      .box{
        @include width($max-width: true, $column: 3);
        margin-top: 2rem;
        @include breakpoint(tablet){
          @include width($max-width: true, $column: 2);
        }
        @include breakpoint(phone){
          @include width($max-width: true, $column: 1);
        }
      }
    }
  }
  &-layout {
    &-1column {
      &:not(.cms-home) {


        .column {
          @include content(outer);
          margin-top: 3rem;

          > div, > form {
            /*@include content(outer);*/
          }

          .results {
            padding-top: 10rem;
          }

          .inner-container, .cart-empty, .checkout-success {
            @include flex($direction: column, $wrap: true);
          }
        }
      }
    }

    &-2columns {
      &-left, &-right {
        .column {
          @include width($gap: 30);

          &s {
            @include content(outer);
            @include flex(space-between, null);
            padding: {
              bottom: 5rem;
              top: 5rem;
            }
          }
        }
      }

      &-right {
        .columns {
          @include flex($direction: row-reverse, $wrap: true);
        }
      }
    }

    &-category-full-width {
      .column {
        padding: {
          left: 2rem;
          right: 2rem;
        }
      }
    }
  }
}
.widget{
  padding: {
    top:5rem;
    bottom:0;
  }
  @include breakpoint(tablet){
    padding: {
      top:4rem;
      bottom:4rem;
    }
  }
}
.customer-account-logoutsuccess {
  .column {
    @include content(outer);
  }
}
/**** Filter *****/
.filter{
  @include flex($wrap: true);
  &-content{
    width: 100%;
  }
  &-title{
    @include fontDefault(1.8, 3.1rem, $colorSecondary, 600);
  }
  &-subtitle{
    display: none;
    @include fontDefault(1.8, 3.1rem, $colorSecondary, 600);
  }
  &-options{
    @include flex($direction: column, $flex: false);
    &-item{
      max-width: 36rem;
      @include overlay(null, null, 100%, auto, relative, $z-index: 2);
      margin-left: 3rem;
      @include transition;
      &:first-child{
        margin-left: 0;
      }
      &.active{
        .filter {
          &-options {
            &-title{
              &:after{
                transform: rotate(180deg);
              }
            }
            &-content {
              ol {
                max-height: 100vh;
                overflow: auto;
                transition-duration: 1s;
              }
            }
          }
        }
      }
    }
    &-title{
      @include fontDefault(1.8, 3.1rem, $colorSecondary, 600);
      padding: {
        bottom: 1.5rem;
      }
      position: relative;
      z-index: 1;
      &:after{
        @include overlay(null, 0, null, null, $right: true);
        vertical-align: center;
        @include transition;
      }
    }
    &-content{
      margin-bottom: 0;
      ol{
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        li{
          @include border;
          margin-bottom: 0.5rem;
          &:last-child{
            margin-bottom: 0;
          }
          &:hover{
            @include border($color: rgba($colorPrimary, .6));
            a{
              background: rgba($colorBorder, .6);
            }
          }
          a{
            @include flex(null, center);
            width: 100%;
            @include fontDefault(1.5, normal, $colorPrimary, 500);
            padding: 0.5rem;
          }
          .count{
            margin-left: auto;
            &:before{
              content: "(";
              margin-left: .8rem;
            }
            &:after{
              content: ")";
            }
          }
        }
      }
    }
  }
  &-label{
    white-space: nowrap;
  }
  &-current{
    padding: 0 0 2rem;
    &-subtitle{
      display:none;
      padding-right: 5rem;
      @include fontDefault(2, null, $colorSecondary, 700);
    }
    .item{
      margin: 1rem 0 0 0;
      padding: .8rem 1rem;
      width: 100%;
      @include flex(null, center);
      @include fontDefault(1.4, normal, $colorPrimary, 500);
      @include border;
      border-radius: .4rem;

      .action{
        margin-left: auto;
      }
    }
    + .filter-actions{
      margin-bottom: 3rem;
    }
  }
  &-label{
    &:after{
      content: ":";
      margin: 0 .8rem 0 0;
    }
  }
  &-value{
    @include fontDefault($weight: 700);
  }
  &-count-label{
    display: none;
  }
}
#toolbar-amount{
  display: none;
}
/**** Toolbar *****/
.toolbar {
  @include flex(flex-end);
  &-products{
    padding-bottom: 4rem;
  }
  .limiter, .sorter{
    @include flex(centery);
    position: relative;
    label{
      @include fontDefault(1.6,$colorPrimary, normal);
    }
  }
  .limiter{
    padding: 0;
    margin: 0;
    select{
      margin-left: 0;
    }
  }
  select {
    @include fontDefault(1.6, $weight: normal);
    @include overlay(null, null, auto, $position: relative, $z-index: 1);
    cursor: pointer;
    padding: 0.7rem 0.5rem;
    background: transparent;
    margin: 0 1rem;
    @include border;
  }
  option{
    @include fontDefault(1.6, normal);
  }
  .limiter{
    label{
      display: none;
    }
  }
  .sort {
    &-asc {
      &:before {
        content: "#{$iconSortAsc}";
      }
    }
    &-desc {
      &:before {
        content: "#{$iconSortDesc}";
      }
    }
    &er {
      &-action {
        &.action {
          padding: 0;
          @include border(0);
          &:hover{
            background: transparent;
            color: initial;
          }
        }
        &:before {
          @include fontIcon;
        }
        span {
          display: none;
        }
      }
    }
  }
  &.customer-addresses-toolbar {
    @include fontDefault(1.4, 2rem,null, bold);
    text-align: right;
    margin: 0;
    width: 100%;
  }
  /*Toolbar Sorter*/
  &-sorter {
    text-align: right;
    align-items: center;
    z-index: 1;
  }
  .pages {
    display: none;
  }
}

/**** Pages *****/


.products-grid + .toolbar-products{
  .limiter, .sorter{
    display: none;
  }
}

/**** Pages Titles *****/
.breadcrumbs{
  background: $colorLightGrey;
  .item{
    @include fontDefault(1.4);
    &:nth-last-child(2) {
      a {
        font-weight:bold;
      }
    }
    &:last-child{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a{
      color: $colorPrimary;
      &:after{
        content: ">";
        margin: 0 1rem;
      }
    }
    strong{
      @include fontDefault(null, null, $weight: normal);
    }
    &s{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include content(outer);
      padding: {
        bottom: 1rem;
        top:1rem;
      }
      @include flex(flex-start, center);
    }
  }
}
.cms-page-header{

}/* .category-page-header */
.category{
  &-list{
    .category{
      &-item{
        @include border;
        margin-bottom: 1.5rem;
        @include transition;
        &.active{
          background: $colorTertiary;
          border-color: $colorTertiary;
          a{
            color: $colorWhite;
          }
          &:hover{
            border-color: $colorTertiary;
            a{
              background: transparent;
            }
          }
        }
        &:hover{
          @include border($color: rgba($colorPrimary, .6));
          a{
            background: rgba($colorBorder, .6);
          }
        }
        a{
          @include transition;
          width: 100%;
          display: block;
          @include fontDefault(1.5, normal, $colorPrimary, 500);
          padding:1.6rem;
        }
      }
    }
  }
  &-view{
    h2{
      margin-bottom: 2rem;
    }
  }
  &-description{
    @include fontDefault(1.6, 2.5rem);
  }
  &-image{
    margin-bottom: 3rem;
    line-height: 0;
    width: 100%;
    img{
      width: 100%;
    }
  }
}
#go-back{
  @extend .action;
  margin-left: 0;
  margin-top: 1rem;
  width: 100%;
}
.cms-page-view{
  .inner-container{
    padding: {
      bottom: 3rem;
    }
    /* @include content(outer); */
  }
}
.page {
  &-title-wrapper {
    @include content(outer);
    text-align: left;
    color: $colorPrimary;

    .column & {
      padding: 0;

      + .category-description {
        margin-bottom: 3rem;
      }
    }

    h1 {
      @include fontDefault(4, 4rem, $colorPrimary, 300, uppercase);
      margin-bottom: 4rem;
    }

    .cms-page-view & {
      margin-top: 4rem;
    }

    .column &, {
      padding: 0;
    }

    .product-info-header & {
      h1 {
        margin-bottom: 1rem;
      }
    }

    & + .breadcrumbs {
      padding-top: 0;
    }
  }
}

.icon{
  @include flex(centery);
  &:after{
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    @include border(0, $radius: 50px);
  }
  &.unavailable{
    &:after{
      background: $colorRed;
    }
  }
  &.available{
    &:after{
      background: $colorSecondary;
    }
  }
}

/*.request-sample{
  a{
    &:after{
      @include fontIcon(1.5, 400, $colorSecondary);
      content: $iconCartBorder;
      margin-right: 1rem;
      display: block;
    }
  }
}*/
/*.declaration-of-conformity{
  a{
    &:after{
      content: $iconDownload;
    }
  }
}*/
.go-to-additional{
  display: inline-block;
  width: 100%;
  @include fontDefault(1.4, normal, $colorSecondary, normal);
  @include flex(flex-start, center);

  i {
    font-size: 1.5rem;
    margin-left: 1rem;
  }
}
