/**** Table *****/
/* Table Simple */
table {
  border-spacing: 0 !important;
  width: 100%;
  border-collapse: collapse;
  &.history{
    margin-bottom: 2rem;
  }
  thead {
    tr {
      @include fontDefault($fontTable, 1, null, bold);
      line-height: 1;
      font-weight: bold;
      text-align: center;
      @include border(1px, $colorLighterGrey, $bottom: true);
      &:first-child {
        text-align: left;
      }
      th {
        &.col.graduated-prices{
          position:relative;
          &:after{
            width: 6rem;
            height: 6rem;
            content: "";
            background: transparent url($imageDir + "tierprice.png") 100% 100% / contain no-repeat;
            position: absolute;
            bottom: 0;
            left: 50%;
          }
        }
      }
    }
  }
  tfoot{
    width: 100%;
    display: table;
    background: $colorLightGrey;
    margin-top: 5rem;
    th{
      @include fontDefault($fontTable, 1, null, bold);
    }
    tr{
      width: 100%;
      border-bottom: 1px solid $colorWhite;
    }
    .amount{
      text-align: right;
    }
    .mark{
      font-weight: 600;
      color: $colorPrimary;
      padding: 1rem;
      text-align: left;
    }
  }
  th, td{
    &.qty{
      text-align: center;
    }
  }
  th {
    padding: 1rem 0;
    text-align: left;
    @include fontDefault(1.4, null, $colorDarkGrey, normal);
    &.subtotal {
      text-align: right;
    }
    &.price{
      text-align: left;
    }
    &.col{
      padding: 1rem 0;
      white-space: nowrap;
      &.action {
        padding: 0;
        width: 100%;
        @include border(0);
        border-radius: 0;
      }
    }
    &.item {
      text-align: left;
    }
  }
  &.simple{
    tr{
      @include fontDefault($fontTable, 1, null, 500);
      &:nth-child(even) {
        background-color: $colorLightGrey;
      }
    }
    tbody{
      border:0;
    }
    td.qty, td.col {
      padding: 2rem 1rem;
    }
  }
  tbody{
    width: 100%;
    padding: .6rem 0;
    @include border($color: $colorLighterGrey, $bottom: true);
    &.graduated-prices{
      td{
        &.col.graduated-prices{
          cursor: initial;
        }
      }
      .detailed &{
        td{
          padding-left: 2rem;

        }
      }
    }
    &.hidden{
      display: none;
    }
  }
  .control {
    &.size {
      text-align: right;
      @include flex(flex-end);
      padding-right: 8.2rem;
    }
  }
  td {
    padding: 1rem 0;
    text-align: left;
    @include fontDefault($fontTable, 1, null, 500);
    &.amount{
      padding: 1rem 0;
    }
    &.col {
      position: relative;
      text-align: left;
      padding: 3rem 1rem;
      display: table-cell;
      vertical-align: top;
      color: $colorDarkGrey;
      &.graduated-prices{
        cursor: pointer;
      }
      .cart{
        &-price{
          display: inline-block;
          @include fontDefault(1.6, 2.6rem, $colorDarkGrey, normal)
        }
      }
      .product {
        &-notice-img{
          margin-right: 1rem;
          max-width: 3rem;
          line-height: 0;
        }
        &-item {
          &-notice{
            padding: 1rem 0;
            @include fontDefault(1.4, 3.2rem, null);
            p{
              @include fontDefault(1.4, 3.2rem, null);
            }
          }
          &-details {
            display: table-cell;
            padding: 0 3rem;
            vertical-align: top;
            text-align: left;
            @include fontDefault(1.8, 2.6rem, $colorDarkGrey);
            >span{
              display: block;
            }
          }
          &-brand {
            width: 100%;
            display: block;
            @include fontDefault($fontTable, 1, null, bold);
            text-transform: uppercase;
          }
        }
        strong {
          line-height: 1;
        }
      }
      &.action{
        @include border(0);
        display: table-cell;
        background: transparent;
        &:hover{
          background: transparent;
          color: initial;
        }
      }
    }
    &.item {
      padding: 3rem 0;
      display: block;
      width: 100%;
      a {
        display: table-cell;
        width: auto;
        span {
          position: relative;
          display: block;
          img {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(0, 0);
          }
        }
      }
    }
    &.price {
      text-align: left;
    }
    &.qty {
      text-align: center;
      .qty{
        text-align: center;

        margin: 0;

      }
      label{
        display: inline-block;
      }
      .label {
        display: none;
      }
      input {
        display: inline-block;
        @include fontDefault(null, null,normal, $colorPrimary);
        cursor: text;
        padding: .6rem 0;
        width: 6rem;
        background: $colorLightGrey;
        @include border(0);
      }
    }
    &.subtotal {
      text-align: right;
    }
  }
  .item {
    &-options {
      margin-top: 1rem;
      @include fontDefault($fontTable, 1, null, bold);
      line-height: 2rem;
      @include flex($wrap: true);
      dl {
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0
      }
      dt {
        float: left;
        width: 50%;
        /* adjust the width; make sure the total of both is 100% */
        padding: 0;
        font-weight: 600;
        margin: 0
      }
      dd {
        float: left;
        width: 50%;
        /* adjust the width; make sure the total of both is 100% */
        padding: 0;
        margin: 0;
      }
    }
    &-actions {
      position: relative;
      td {
        padding: 0;
        text-align: right;
        .action {
          &-edit, &-delete{
            color: $colorSecondary;
            @include flex($direction:row-reverse);
            text-align: left;
            span{
              display: inline-block;
              @include fontDefault(1.4, 2.6rem, null, normal,none );
              margin-right: 1.2rem;
            }
          }
          &-delete{
            &:before{
            }
          }
          &-edit{
            &:before{
            }
          }
          &s-toolbar {
            @include flex(flex-end);
            padding: {
              bottom: 2rem;
            };
          }
        }
      }
    }
  }
  /* Table Account */
  .account &{
    .table-caption {
      display: none;
    }
    td, th{
      &.actions{
        @include flex(flex-start, center);
        width: auto;
        .delete{
          margin-left: 1.5rem;
        }
      }
    }
    td{
      padding: 1.4rem 0 0;
      @include fontDefault($fontTable, 1, null, bold);
      vertical-align: middle;
      .cart-price{
        margin: 0;
      }
      &.qty{
        .item{
          @include flex(flex-start, center);
        }
        .title{
          padding: {
            bottom: 0;
            right: .7rem;
          }
          @include fontDefault($fontTable,normal, $weight: normal)
        }
      }
    }
    tr{
      &:last-child{
        td{
          padding-bottom: 1.4rem;
        }
      }
    }
    tfoot{
      td{
        padding:1rem ;
      }
    }
    &.order-items {
      margin-bottom: 1.5rem;
      tr {
        @include flex(space-between);
        td, th {
          display: block;
          &.name {
            width: 40%;
          }
          &.sku {
            width: 10%;
          }
          &.price {
            width: 15%;
            padding-left: 4rem;
          }
          &.qty {
            width: 30%;
            padding: .7rem 1rem;
            ul.items-qty {
              @include flex;
              .item {
                padding-right: 1rem;
                @include flex(centery);
                .title{
                  @include fontDefault($fontTable, $weight: 500);
                  padding: 0;
                  @include border(0);
                  margin-right: 1.2rem;
                }
              }
            }
          }
          &.subtotal {
            text-align: right;
            width: 15%;
          }
        }
      }
      tfoot {
        margin-top: 2rem;
        display: block;
      }
    }
  }
  &.items{
    th{
      &.col{
        &.price{
          text-align: center;
        }
      }
    }
  }
  .opc-block-summary &, .cart-totals &{
    width: 100%;
    tbody{
      @include border(0);
    }
    th, td {
      @include fontDefault(1.6, normal, $colorFontDefault, normal);
      text-align: left;
      padding: 1rem 0;
    }
    tr{
      td{
        text-align: right;
      }
    }
  }
  .step-content &{
    td{
      &.col{
        width: 100%;
        text-align: left;
        color: $colorPlaceholder;
        padding: 0 0 0 1.5rem;
        &-method{
          cursor: pointer;
          &:first-child{
            width:auto;
            padding: 0;
            @include flex(centery);
          }
        }
        input{
          width: auto;
          color: $colorPlaceholder;
        }
      }
    }
    &.table-checkout-shipping-method{
      margin-top:0;
      tr {
        &:first-child{
          td{
            background: transparent;
            text-align: left;
            color: $colorPrimary;
          }
        }
        &.row {
          @include flex;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          @include border($bottom: true);
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            @include border(0);
          }
        }
      }
    }
  }
  &.products{
    th{
      font-weight: bold;
      &:nth-of-type(3){
        padding: {
          right: .5rem;
          left: .5rem;
        }
        text-align: center;
      }
    }
    tr{
      position: relative;
    }
    td, th{
      text-align: left;
      padding: 3.4rem 0;
      @include border(1px,$colorGrey, $bottom: true );
      @include fontDefault(1.6, normal, $colorFontDefault);
      vertical-align: middle;
      a{
        color: $colorFontDefault;
        font-weight: bold;
      }
      &:first-child{
        text-align: left;
      }
    }
    td{
      padding: {
        bottom: 4.5rem;
      };
    }
    th{
      padding: 3.4rem 3rem 1.5rem 0;
      &:last-child{
        padding-right: 0;
      }
    }
  }
  .inner-container &{
    margin-top: 2.5rem;
    th{
      &.col{
        background: transparent;
        @include fontDefault(null, null,$colorDarkGrey, normal);
        &.price{
          text-align: center;
        }
      }
    }
    tbody{
      border-bottom: 0;
      td{
        padding: 1.4rem 0;
        width: 50%;
        vertical-align: top;
        color: $colorDarkGrey;
        @include border($bottom: true, $color: $colorLighterGrey);
      }
    }
  }
  &.table-checkout-shipping-method{
    tbody{
      &:last-child{
        border-bottom: 0;
      }
    }
  }
  &.cart{
    th.col{
      padding: {
        left: 1rem;
        right: 1rem;
      };
      &.item{
        padding-left: 0;
      }
    }
    .product-item{
      &-photo{
        padding: 0;
      }
      &-name{
        text-align: left;
        min-height: initial;
        height: auto;
        display: block;
        background: transparent;
        padding-top: 0;
        a{
          @include fontDefault(1.6, 2.6rem, $colorFontDefault);
          color: $colorDarkGrey;
        }
      }
    }
  }
  &.additional-attributes{
    margin-bottom: 1rem;
    thead{
      th{
        @include fontDefault(1.6, 3rem, $weight: 500);
      }
    }
    tbody{
      tr{
        @include border($bottom: true);
      }
    }
    th, td{
      @include fontDefault(1.5, normal, $weight: normal);
      padding:.9rem 0;
    }
    td.data{
      padding: 1rem 0 1rem 2rem;
      vertical-align: top;
    }
  }
  &.grouped{
    thead{
      th{
        text-align: center;
        @include fontDefault(1.5, 1.8rem, $weight: 500);
        padding: 0 1rem;
        &.graduated-prices{
          width: 5.6rem;
          max-width: 5.6rem;
          color: $colorTertiary;
          hyphens: auto;
          word-break: break-word;
          white-space: normal;
        }
        &.item{
          padding-left: 0;
        }
        &.item, &.price{
          text-align: left;
        }
        &.graduated-prices{
          padding: 0;
        }
      }
    }
    tbody{
      &.graduated-prices{
        background: $colorLightGrey;
        td{
          &.graduated-prices{
            background: transparent;
          }
          &.item {
            text-align: right;
          }
        }
      }
    }
    td{
      padding: 1rem;
      @include fontDefault(1.6, 2rem, $weight: normal);
      vertical-align: middle;
      .product-item {
        &-name{
          line-height: normal;
          vertical-align: middle;

        }
        &-image {
          margin-right: 2rem;
          vertical-align: middle;
          display: inline-flex;
        }
      }
      &.qty{
        text-align: center;
        padding: 0 1rem;
        input{
          background: $colorWhite;
          padding:0;
          @include border;
        }
      }
      &.price{
        text-align: left;
        .price-box{
          margin-right: 0;
          .price-container {
            margin:0;
            .price-wrapper {
              margin:0;
            }
          }
        }
      }
      &.item{
        padding-left: 2rem;
        width:auto;
        display:table-cell;
        img{
          max-width: 2.4rem;
          max-height: 2.4rem;
          overflow: hidden;
          //@include border(0, $radius: 50%)
        }
      }
      &.graduated-prices{
        background: $colorTertiary;
        padding: 0;
        position:static;
        span{
          @include flex(centery);
          width: 100%;
          height: 100%;
          &:after{
            width: auto;
            height: auto;
            @include fontIcon(2.4, 300, $colorWhite);
            content: $iconArrow;
            transform: rotate(180deg);
            display: block;
            @include transition;
          }
        }
      }
    }
    .price-details{
      display: none;
    }
    tbody{
      &.active{
        background: $colorLightGrey;
        td {
          &.item, .price{
            font-weight: bold;
          }
          .price{}
          &.qty{
            input{
              @include border;
            }
          }
          &.graduated-prices {
            span {
              &:after {
                transform: rotate(0);
              }
            }
          }
        }
      }
    }
  }
  &:not(.table){ /* .aboutUs-table */
    margin-top: 3rem;
    tbody{
      @include border(0);
    }
    tr{
      a{
        font-weight: normal;
      }
      td{
        padding: 1rem;
        width: 20%;
      }
      &:first-child{
        td{
          background: $colorSecondary;
          color: $colorWhite;
          text-align: center;
        }
      }
    }
  }
  @include breakpoint(largeDesktop) {
    thead {
      tr {
        th {
          &.col.graduated-prices{
            &:after{
              left: 0;
            }
          }
        }
      }
    }
    &.cart{
      tbody{
        display: inline-block;
      }
      tr{
        @include flex($wrap: true);
        padding-bottom: 2rem;
        position: relative;
      }
      th{
        display: none;
      }
      td{
        &.col{
          @include width($gap: 30);
          text-align: left;
          margin-left: 30rem;
          padding: 2rem 3rem 0 0;
          position: initial;
          > * {
            display: inline-block;
          }
          &[data-th] {
            &:before {
              @include fontDefault($fontTable, $lineHeightTable, null, normal);
              content: attr(data-th);
              width: 100%;
              padding: 0;
              text-align: left;
              display: block;
            }
          }
          .actions-toolbar{
            position: absolute;
            padding: 0;
            top: 1rem;
            right: 0;
            margin-right: 0;
            margin-top: 0;
            @include flex(centery);
            a{
              display: inline-block;
            }
          }
          .product-item-details{
            width: 100%;
            padding-left: 0;
          }
          &.action{

          }
        }
        &.item{
          width: 30rem;
          margin-left: 0;
          @include overlay(0, 0, null, auto);
          &[data-th]{
            &:before{
              display: none;
            }
          }
        }
      }
    }
  }
  @include breakpoint(desktop) {
    .item-options {
      dt, dd {
        width: 100%;
      }
      dt {
        margin-bottom: 0;
      }
      dd {
        margin-bottom: 1.5rem;
      }
    }
  }
  @include breakpoint(smallDesktop){
    @include flex($wrap: true);
    tr{
      @include flex($wrap: true);
      &.item-info{
        @include flex(space-between);
      }
      th {
        display: none;
      }
      td, .cart & td{
        text-align: left;
        &.amount{
          width: 100%;
          @include flex(flex-start, center);
          &[data-th] {
            &:before {
              content: attr(data-th);
              width: auto;
              text-align: left;
              padding: 0 1rem 0 0;
              @include fontDefault($fontTable, 1, null, normal);
              display: block;
              flex-shrink: 0;
            }
          }
          span{
            &.price{
              justify-content: flex-start;
            }
            width: 100%;
            @include flex(flex-start, center);
            &[data-th] {
              &:before {
                flex-shrink: 0;
                content: attr(data-th);
                width: auto;
                padding: 0 1rem 0 0;
                text-align: left;
                @include fontDefault($fontTable, 1, null);
                text-transform: uppercase;
                display: block;
              }
            }
          }
        }
        &.col[data-th] {
          &:before {
            @include fontDefault($fontTable, $lineHeightTable, $weight: 600);
            content: attr(data-th);
            width: 100%;
            padding: 0;
            text-align: left;
            display: block;
          }
        }
        &.col{
          width: auto;
          text-align: left;
          margin-left: 0;
          padding: 0 1rem 2rem 0;
          position: inherit;
          &.action{
            @include overlay(1rem, 0, auto, auto, $right: true);
          }
          .actions-toolbar{
            padding: 0;
            margin-right: 0;
            margin-top: 0;
            @include flex(centery);
            a{
              width: 3.2rem;
              height: 3.2rem;
              display: inline-block;
              @include border(null, $colorPrimary, $radius: 50%);
            }
          }
          &:first-child{
            padding-top: 1rem;
          }
        }
        &.subtotal {
          padding-right: 0;
        }
        &.item {
          width: 100%;
          padding-right: 0;
          padding-top: 0;
          @include flex($wrap:true);
          position: initial;
          &.col:before{
            display: none;
          }
          > a {
            width: auto;
            display: inline-block;
          }
          .product {
            &-item {
              &-photo{
                min-height: inherit;
              }
              &-details {
                display: inline-block;
                vertical-align: top;
                @include width($gap: 12);
                padding: {
                  right: 0;
                  top: 2.5rem;
                }
              }
            }
          }
        }
        &.qty{
          padding-top: 0
        }
      }
    }
    thead {
      width: 100%;
      display: none;


    }
    tbody{
      &:first-of-type{
        td.col{
          &:first-child{
            padding-top: 0;
          }
        }
      }
      &.cart.item{
        position: relative;

      }
    }
    tfoot{
      tr td {
        &.amount{
          span.price, strong {
            @include flex(flex-end);
            width: 100%;
          }
        }

      }
    }
    .account &{
      tr{
        padding-top: 0;
        td.col {
          width: 100%;
          @include flex(flex-start, center, $wrap: true);
          @include fontDefault(null, null, $weight: normal);
          .action{
            @include fontDefault($fontTable);
          }
          &[data-th]::before {
            width: auto;
            margin-right: 1rem;
          }
          .price {
            margin-top: 0;
          }
          a {
            margin-top: 0;
          }
          &.price{
            padding: 0 0 2rem 0;
          }
          &.actions{
            a{
              display: inline-block;
              width: auto;
              padding: 0;
            }
          }
          .item-options{
            margin-top: 0;
            @include flex;
            width: 100%;
            dd{
              width: auto;
              margin-left: .5rem;
              margin-right: 2rem;
            }
            dt{
              width: auto;
            }
          }
        }
      }
    }
    &.table {
      &-order-items {
        tr td {
          strong {
            color: $colorSecondary;
          }
          &.price {
            padding: .7rem 1rem;
          }
        }
      }
      &-additional-addresses-items{
        tr{
          padding-bottom: 2rem;
          border-bottom: 1px solid $colorBorder;
          margin-bottom: 2rem;
          &:last-child{
            @include border(0);
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
    .cart{
      .item{
        &[data-th]::before{
          display: none;
        }
      }
    }
    &.products{
      tbody{
        @include border(0);
      }
      .price {
        &-box {
          margin: 0;
        }
        &-details{
          text-align: left;
          bottom: 1rem
        }
      }
      .product{
        &-item-price{
          margin-top: 0;
        }
      }
      td{
        width: auto;
        @include border(0);
        padding: 0;
        > span, > strong{
          display: block;
          text-align: left;
          padding: 0 2rem 1.5rem 0;
        }
        &:first-child{
          width: 100%;
        }
        &:last-child{
          padding-right: 0;
        }
        &:nth-of-type(7){
          min-width: 10rem;
          margin-left: auto;
        }
        &:last-child{
          width: 1.6rem;
        }
      }
      tr{
        @include border($bottom: true, $color: $colorGrey);
        padding: 3rem 0 4rem;
        @include flex(space-between);
      }
    }
    &.history, &.recent{
      tr{
        margin-bottom: 3rem;
        @include border($bottom: true, $color:$colorLighterGrey);
        &:last-child{
          margin-bottom: 0;
          @include border(0);
        }
      }
    }
    .cart-totals &, .opc-block-summary &{
      th {
        display: inline-block;
        width: 50%;
      }
      tr{
        @include flex(space-between, center);
      }
      td{
        &.amount {
          width: 50%;
          &[data-th]::before {
            display: none;
          }
          strong{
            width: 100%;
          }
          span {
            &.price{
              @include flex(flex-end, null);
            }
            &[data-th]::before {
              display: none;
            }
          }
        }
      }
    }
    .inner-container &{
      thead{
        display: none;
      }
      tbody{
        border-bottom: 0;
      }
      tr{
        padding-top: 2rem;
      }
      td{
        &.col{
          padding-left: 0;
          padding-bottom: 1rem;
          &[data-th]::before{
            padding-bottom: 0;
          }
        }
      }
    }
    .step-content &{
      td.col{
        @include flex(flex-start, $wrap: true);
        @include width(100%, $gap: 2);
      }
    }
    &.grouped{
      display: table;
      //margin-top: 5rem;
      tr{
        display: table-row;
      }
      thead{
        display: table-row-group;
      }
      th{
        display: table-cell;
      }
      td{
        vertical-align: middle;
        &.col{
          padding: 1rem .5rem;
        }

        &.price{
          word-break: initial;
        }
        &.item{
          padding: 0;
          padding-left: 2rem;
          @include flex($wrap: false);
        }
        &.col[data-th]:before{
          display: none;
        }
      }
      tbody{
        &:first-of-type{
          td.col:first-child{
            padding-top: 2rem;
          }
        }
        &.graduated-prices{
          tr.item{
            .graduated-prices{
              background: transparent;
            }
          }
        }
      }
    }
    &.additional-attributes {
      tbody{
        @include border(0);
      }
      td.data {
        padding-left: 0;
        &[data-th]::before {
          font-weight: 600;
        }
      }
    }
  }
  @include breakpoint(phone){
    tr {
      &.item-actions{
        td{
          .actions-toolbar{
            @include flex(centery, $wrap: nowrap);
            .action{
              padding: 0;
            }
            .action + .action{
              margin-top: 0;
              padding-left: 1rem;
            }
          }
        }
      }
      td {
        &.col {
          width: 100%;
          @include flex(flex-start, center, $wrap: true);
          padding-bottom: 1.5rem;
          &[data-th]::before{
            width: auto;
            margin-bottom: 0;
            margin-right: 1rem;
          }
          .cart-price{
            margin-top: 0;
          }
        }
        &.item{
          @include flex($wrap: false);
          .product {
            &-item, &-image {
              &-details {
                width: 100%;
                padding: {
                  left: 2rem;
                  top: 2rem;
                  right: 0;

                }
              }
              &-photo, &-container {
                max-width: 10rem;
              }

            }
          }
        }
        &.qty{
          .qty input{
            padding: {
              top: 0;
              bottom:0;
            };
          }
        }
      }
    }
    .item-actions{
      td{
        width: 100%;
      }
    }
    tbody.cart.item td .actions-toolbar{
      position: relative;
      @include flex($wrap: true);
      a{
        padding: .2rem .7rem;
        line-height: normal;
        margin: {
          right: 0;
          bottom: 2rem;
        }
      }
    }
    &.grouped{
      thead{
        display: none;
      }
      tbody{
        &.graduated-prices{
          tr{
            @include border($bottom: true);
            td {
              &.item{
                width: 33%;
                vertical-align: top;
                padding: 1rem;
                gap: 0;
                @include flex(null, flex-start, $wrap: true);
                &:before{
                  display: none;
                }
              }
              &.sku{
                display: none;
              }
            }
          }
        }
      }
      tr{
        @include flex(null, flex-start, $wrap: true);
        position: relative;
      }
      td {
        .product-item {
          &-image {
            margin-right: 1rem;
          }
          &-name{
            width: 100%;
            line-height: normal;
            margin-top: 0;
          }
        }
        &.col {
          width: 33%;
          vertical-align: top;
          padding: 0.5rem;
          gap: 0;
          @include flex(null, flex-start, $wrap: true);
          &[data-th]::before {
            display: inline-block;
            margin-bottom: 0;
            width: 100%;
            margin-right: 0;
            @include fontDefault(1.5, 1.8rem, $weight: 500);
          }
          &.item {
            &[data-th]::before {
              display:none;
            }
          }
        }
        &.item{
          padding-right: 6rem;
          padding-left: 0.5rem;
          width: 100%;
        }
        &.graduated-prices{
          @include overlay(0, 0, 3.8rem, 3.8rem, $right: true);
          &[data-th]::before {
            display: none;
          }
        }
        &.availability{
          display: none;
          &[data-th]::before {
            display: none;
          }
        }

      }
    }
    &.additional-attributes {
      td{
        @include flex($wrap: true);
      }
    }
    .cart &{
      tr td{
        &.item{
          @include flex($wrap: true);
          .product-item-details{
            width: 100%;
          }
        }
      }
    }
    &:not(.table) {
      empty-cells: hide;
      tr {
        td {
          width: 100%;
          &:empty {
            padding: 0;
            display: none;
          }
        }
        &:first-child {
          display: none;
        }
      }
    }

  }/* END 480px */
}
.additional-addresses, .orders-recent{
  width: 100%;
  overflow: auto;
}
/* Table .inner-container */



/*** RESPONSIVE ***/




