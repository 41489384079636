.webcrossing-widget-category-boxlist{
  @include content(outer);
  ul{
    @include flex(center, $wrap: true, $gap: 2);
    li{
      margin-bottom: 2.6rem;
      position: relative;
      z-index: 1;

      @include transition;
      text-align: left;
      padding: 1rem;
      @include width($column: 4);
      @include border($color: $colorWhite);
      @include boxShadow(0, .1, .5, 0, rgba($colorBlack, .12));

      &:hover{
        @include boxShadow(0, .1, .5, 0, $colorTertiary);
        @include border($color: $colorTertiary)
      }
      h2{
        padding: 2rem 0;
        margin: 0;
        text-align: center;
        @include fontDefault(1.4, normal, $colorTitleDefault, 500);
      }

    }
  }
  .box-wrapper{
    @include overlay;
    padding:0 7rem;
    @include flex(centery);
    overflow: hidden;
  }
  .action{
    @include buttonWhite;
    background: transparent;
    text-transform: uppercase;
    margin-top: 2rem;
    & + .action{
      margin-left: 0;
    }
    &s{
      max-height: 0;
      width: auto;
      overflow: hidden;
      @include transition;
      @include flex(null, center, column);
    }
  }
  /*.overlay{
    background: $colorTertiary;
    @include fontDefault(2.4, 2.8rem, $colorWhite, 500, uppercase);
    padding: 1.5rem 0;
  }*/
  .image{
    width: auto;
    z-index: 1;
    position: relative;
    line-height: 0;
    img{
      @include transition;
      transform: scale(1);
    }
  }
  .hidden{
    max-height: 0;
    overflow: hidden;
    @include transition;
    color: $colorWhite;
    a{
      @include fontDefault($color: $colorWhite, $weight: bold, $style: underline);
    }
    > a{
      @include fontDefault(2, 2.4rem);
    }
    .text{
      @include fontDefault(1.8, 2.2rem, 300);
      margin-bottom: 2rem;
      a{
        text-decoration: none;
      }
    }
  }
  &.alternative{
    .action{
      background: $colorSecondary;
      border-color: $colorSecondary;
    }
    ul{
      li{
        h2{
          @include transition;
          overflow: hidden;
          max-height: 20rem;
          opacity: 1;
        }

      }
    }
  }
  .block-title{
    padding: {
      right: 2rem;
      left: 2rem;
    };
  }
  /*** RESPONSIVE ***/
  @include breakpoint(smallDesktop){

    .image{
      width: 100%;
      img{
        min-width: 100%;
      }
    }
    ul{
      li{
        @include width($width: 100%, $gap: 3, $column: 3);
        @include flex(null, flex-start);
        padding-top: 0;
      }
    }
  }/* End 1024px */
  @include breakpoint(tablet){
    ul {
      gap: 1rem;
      li {
        @include width($width: 100%, $gap: 1, $column: 2);
        h2{
          @include fontDefault(2, normal, $colorWhite);
        }
      }
    }
  }

  @include breakpoint(phone){
    ul{
      li{
        @include width($gap: 0);
      }
    }

  }
}