#checkout {
  &-loader {
    @include overlay($position: fixed !important, $z-index: 9999);
    /*bottom: 0;
    right: 0;
    */
    margin: auto;
    background: rgba($colorWhite, 0.7);
    @include flex(centery);
    .loader {
      img {
        width: 100%;
        max-width: 6.4rem;
        z-index: 9999;
      }
    }
  }
}
.cart {
  &-totals{
    .table {
      &-wrapper {
        .load.indicator {
          @include overlay($z-index: 9999);
          /*bottom: 0;
          right: 0;
          */
          background-color: $colorLightGrey;
          &:before {
            background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
            border-radius: 0.5rem;
            @include overlay($width: 16rem, $height: 16rem, $z-index: 9999);
            /*bottom: 0;
            right: 0;
            */
            box-sizing: border-box;
            content: "";
            margin: auto;
          }
          > span {
            display: none;
          }
        }
        body > .loading-mask {
          z-index: 9999;
        }
        ._block-content-loading {
          position: relative;
        }
        [data-role="main-css-loader"] {
          display: none;
        }
      }
    }
  }
  &-summary {
    .load {
      &ing-mask {
        position: absolute !important;
        background: rgba($colorWhite, .9);
        margin: 0;
      }
    }
  }
}
.minicart-wrapper{
  ._block-content-loading {
    .counter-number {
      background: $colorWhite;
    }
  }
  .load {
    &er {
      > img {
        max-width: 100%;
        min-width: initial;
        background: transparent;
        min-height: initial;
      }
    }
    &ing-mask {
      @extend .counter-number;
      position: absolute !important;

      bottom: initial;
    }
  }
}
/*Loader*/
.load {
  &er {
    width: 100%;
    height: auto;
    p  {
      display: none;
    }
    > img {
      max-width: 6.4rem;
      @include flex(centery);
      @include overlay(50%, 50%, 100%, auto, $z-index: 9999);
      min-width: 4.4rem;
      min-height: 4.4rem;
      transform: translate(-50%, -50%);
    }
  }
  &ing-mask {
    @include overlay($position: fixed !important, $z-index: 9999);
    /*bottom: 0;
    right: 0;
    */
    margin: auto;
    background: rgba($colorWhite, .7);
  }
  &.indicator {
    background-color: rgba($colorWhite, .7);
    z-index: 9999;
    &:before {
      background: transparent url($imageDir + "loader.gif") no-repeat 50% 50%;
      border-radius: 0.5rem;
      box-sizing: border-box;
      content: "";
      @include overlay($width: null, $height: null);
     /* right: 0;*/
      margin: auto;
    }
    > span {
      display: none;
    }
  }
}