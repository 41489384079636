/**** Prices *****/
.price{
  /* Product List Price */
  .product-item-details &{
	&-details{
	  @include fontDefault(1.2, null, $colorGrey,$style:italic);
	  padding-left: 1rem;
	  margin-top: 1rem;
	  display: none;
	}
	&-box{
	  margin-right: 0;
	  @include flex(centery, row-reverse, $gap: .3rem);
	}
  }
  &-box{
	@include fontDefault(2rem, null, $weight: 500);
	.old-price{
	  .price{
		@include fontDefault(1.7, $weight: normal, $style: line-through);
	  }
	}
	.minimal-price {
        > .price-container {
            justify-content: center;

            .price-label {
                margin-right:0.5rem;
            }

            .price-wrapper {
                font-size: 1.5rem;
                line-height: 2.5rem;
            }
        }

        .price-label {
            font-size: 1.5rem;
            line-height: 2.5rem;
        }
	}
    .normal-price {
        > .price-container {
            display:block;
        }
    }
  }
  &-filling-amount, &-details{
	order:1;
  }
  &-info-sku{
	font-size: 1.3rem;
  }
  &-filling-amount{
	@include fontDefault(1.1, normal);
	margin: 0 0 1rem 0;
  }
  &-details{
	@include fontDefault(1.1, normal, $style: italic);
	a{
	  @include border(null, solid, $colorPrimary, bottom);

	}
  }
  &-per-amount{
	font-size: 1.1rem;
  }
  &-amount-wrap{
	.price-final_price .price{
	  @include fontDefault(3.7, 4.5rem);
	}
  }
  &-final_price {
	color: $colorPrimary;
	&.tax{
	  @include flex(flex-start);
	}
	#product-unit-price, #final-amount-text, > span{
	  display: block;
	  @include fontDefault(1.4, normal, null, 500);
	  margin-bottom: .5rem;
	}
	.price, #mwst-dot{
	  @include fontDefault(1.5, 2.5rem, null, 500);
	  display: inline-block;
	}
	#mwst-dot{
	  @include fontDefault(2.4, 2.9rem);
	  font-size: 2.4rem;
	  line-height: 2.9rem;
	}
  }
  @include breakpoint(tablet){
	&-amount-wrap{
	  .price-final_price{
		@include flex(flex-start, $flex: false);
	  }
	}
  }/* END 768px */
  @include breakpoint(tablet){
	&-amount-wrap{
	  .price-final_price{
		@include flex(center, $flex: false);
	  }
	}
  }
}