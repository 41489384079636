@mixin buttonWhite {
  color: $colorWhite;
  border-color: $colorWhite;
}
#show-password + .label{
  width: auto;
  display: inline-block;
}
button{
  cursor: pointer;
}

.primary + .secondary{
  .action {
    margin-left: 2rem;
    @include breakpoint(phone){
      margin: {
        left: 0;
        top: 2rem;
      }
    }/* 480px */
  }
}
.secondary {
  @include flex(centery);

}
.primary, .secondary{
  @include breakpoint(phone){
    width: 100%;
  }
}
.action + .action{
  margin-left: 1rem;
  .box-tocart &{
    margin-left: 0;
  }
  @include breakpoint(phone){
    margin: {
      left: 0;
      top: 2rem;
    }
  }/* 480px */
}

.action{
  @include border;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  word-break: normal;
  hyphens: none;
  background: $colorButton;
  @include fontDefault($fontButton , 2.2rem, $colorWhite, 500, uppercase, $font: true);
  @include transition;
  @include flex(centery);
  padding: $buttonPadding;
  &:after{
    margin-left: 2rem;
    line-height: 1rem;
  }
  &:before{
    @include fontIcon(1.5, 400);
    margin-right: 1rem;
  }
  @include breakpoint(desktop){
    padding: 1.35rem 1rem;
  }
  @include breakpoint(tablet){
    background: $colorSecondary;
    color: $colorWhite;
    &.showcart, &.showaccount, &.toggle, &.towishlist, &.sorter-action{
      background: transparent;
      @include border(0);
    }
  }
  &:hover{
    background: $colorLightSecondary;
    border-color: $colorLightSecondary;
  }
  &.primary{
    position: relative;
    border-color: $colorTertiary;
    color: $colorWhite;
    background: $colorTertiary;
    span{
      display: inline-block;
      vertical-align: middle;
      .black {
        color: $colorBlack;
      }
    }
    &:hover{
      background: $colorDarkSecondary;
      border-color: $colorDarkSecondary;
    }
  }
  &.secondary, .secondary &{
    @include border($color: $colorPrimary);
    background: transparent;
    color: $colorPrimary;
    .modal-footer &{

    }
    &:after{
      display: none;
    }
    @include breakpoint(tablet){
      @include border($color: $colorSecondary);
    }
  }
  &-accept, &-dismiss{
    @extend .action;
  }
  &.toggle{
    padding: 0;
    @include border(0);
    @extend .no-hover;
  }
  /*&.apply, &-apply{
    background: $colorSecondary;
    &:after{
      display: none;
    }
    &:hover{
    }
  }*/
  &.update{
    &:before{
      content: $iconRefresh;
    }
  }
  &.clear{
    margin-left: auto;
    &:before{
      content: $iconTrash;
    }
    @include breakpoint(desktop){
      margin-left: 0;
    }
  }
  &.continue {
    &:after{
      @include fontIcon(1.5, 400);
      margin-left: 1rem;
      content: $iconArrow;
      transform: rotate(90deg);
      line-height: normal;
      display: inline-block;
    }
  }
  &.apply, &-apply{
    width: 100%;
  }
  &-show{
    text-transform: none;
  }
  &.print{
    margin-top: 2rem;
  }
  &.sorter-action{
    background: transparent;
    color: $colorPrimary;
  }
  &.view{
    @include border(0);
    margin-left: auto;
    @extend .no-hover;
    color: $colorSecondary !important;
    @include breakpoint(phone){
      width: auto;
    }
  }
  &.disabled {
    border-color: $colorGrey;
    background-color: $colorGrey;
    pointer-events: all;
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.delete, &-delete,
  &.edit, &-edit,
  &.change-password,
  &-change-password,
  &.remove, &-remove,
  &.towishlist{
    background: transparent;
    @include border($size:0);
    @include fontDefault(0, normal);
    padding: 0;
    letter-spacing: 0;
    color: $colorSecondary;
    &:after{
      display: none;
    }
    &:hover{
      background: transparent;
    }
    > span {
      display: none;
    }
    &:before {
      @include fontIcon(1.3, 600);
      color: $colorPrimary;
      display: inline-block;
      @include border($color: rgba($colorWhite, .3), $radius: 50%);
      max-width: 3rem;
      width: auto;
      max-height: 100%;
      height: 3rem;
      @include flex(centery);
      margin-right: 0;
    }
    @include breakpoint(tablet){
      background: transparent;
      @include border(0);
    }
    @include breakpoint(phone){
      width: auto;
      margin-top: 0;
    }
  }
  &.back{
    background: transparent;
    @include border;
    color: $colorPrimary;
    &:before{
      content: $iconArrow;
      transform: rotate(270deg);
      @include fontIcon(1.5, 400);
      margin-right: 1rem;
    }
    &:hover{

      border-color: $colorSecondary;
    }
  }
  &.delete, &-delete{
    margin-left: 2rem;
    &:before {
      content: $iconClose;
      font-size: 1.6rem;
    }
    @include breakpoint(phone){
      width: auto !important;
      margin-top: 0 !important;
      margin-left: 2rem !important;
    }
  }
  &.edit, &-edit{
    &:before {
      content: $iconEdit;
    }
    @include breakpoint(phone){
      width: auto !important;
    }
  }
  &.change-password, &-change-password{
    &:before {
      content: $iconKey;
    }
  }
  &.remove, &-remove{
    &:before {
      content: $iconClose;
    }
  }
  &s {
    .form-cart &{
      padding-top: 1rem;
      @include flex(flex-start, center);
      @include breakpoint(desktop){
        @include flex(space-between, $wrap: true);
      }
      .action{
        @include border($color: $colorPrimary);
        &.continue, &.update, &.clear{
          background: transparent;
          width: auto;
          color: $colorPrimary;
          @include transition;
          &:after{
            display: none;
          }
          &:before{
            @include fontIcon(1.5, 400);
            margin-right: 1rem;
          }

          @include breakpoint(desktop){
            width: 100%;
            margin-top: 2rem;
            &:before{
              display: none;
            }
          }
          @include breakpoint(desktop) {
            margin-left: 0;
          }
        }
        &.continue, &.update, &.clear{
          width: auto;
          @include transition;
          &:before{
            @include fontIcon(1.5, 400);
            margin-right: 1rem;
          }
          &:hover{
            background: $colorSecondary;
            color: $colorWhite;
          }
          @include breakpoint(desktop){
            width: 100%;
            margin-top: 2rem;
            &:before{
              display: none;
            }
          }
        }
        &.continue{
          margin-left: 0;
          &:before{
            content: $iconArrow;
            transform: rotate(270deg);
          }
        }
      }
      &-toolbar{
        @include flex(null, center);
        .towishlist span {
          display: none;
        }
      }
    }
    &-toolbar {
      @include flex;
      @include breakpoint(phone) {
        @include flex($wrap: true);
      }
      > div {
        @include flex;
      }

      .wishlist-index-index & {
        @include flex($wrap: true);
        margin-top: 0;
        @include breakpoint(phone) {
          .action + .action {
            margin-top: 0;
          }
        }

        .primary {
          width: 100%;
          @include flex(flex-start, center, $wrap: wrap, $gap: 1rem);
          > .action {
            margin-left: 0;
          }
        }

        .back {

          margin: {
            left: 0;

            top: 3rem;
          }

        }
      }
    }
    .form-create-account &, .customer-container &{
      width: 100%;
    }
    .login-container &{
      width: 100%;
      margin-top: 4rem;
    }
  }
  &.view{
    letter-spacing: 0;
    padding: 0;
    background: transparent;
    color: $colorSecondary;
    &:after{
      display: none;
    }
    &:hover{
      color: $colorSecondary;
    }
  }
  table &{
    &.view{
      letter-spacing: 0;
      padding: 0;
      background: transparent;
      color: $colorSecondary;
      &:after{
        display: none;
      }
      &:hover{
        color: $colorSecondary;
      }
    }
  }
  &.login{
    /*background: $colorSecondary;
    border-color: $colorSecondary;*/
    &:hover{
      /*background: $colorDarkSecondary;
      border-color: $colorDarkSecondary;*/
    }
  }
  &.remind{
    /*color: $colorWhite;*/
  }
  &.captcha-reload{
    color: $colorWhite;
  }
  &.showcart, &.showaccount{
    background: transparent;
    letter-spacing: 0;
    color: $colorSecondary;
    padding:0;
    gap: 1.1rem;
    @include transition;
    font-weight: normal;
    @include border(none);
    &:after{
      display: none;
    }
    span{
      &:hover{
        text-decoration: none;
      }
    }
    &:hover{
      opacity: .8;
      @include border(0);
      text-decoration: none;

    }
  }
  &.subscribe{
    border-color: $colorWhite;
    color: $colorWhite;
  }
  &.towishlist{
    &:before{
      content: $iconStar;
    }
  }
  &.search{
    &:after{
      display: none;
    }
  }
  &.tocart{
    line-height: normal;
    min-width: 23rem;
    &:after{
      content: $iconCartWhite;
      @include fontIcon;
      width: auto;
      height: auto;
    }
  }
  &-toggle{
    @extend .no-hover;
    display: inline-block;
    padding: 0;
    margin-bottom: 1rem;
    @include border(0);
    @include breakpoint(tablet){
      &:hover{
        @include border(0);
      }
    }
  }
  .product-options-bottom &{
    &.towishlist{
      padding: 1.4rem;
      background: $colorGrey;
      margin-left: 1.2rem;
      width: auto;
      &:before{
        opacity: .8;
      }
      @include breakpoint(phone){
        margin-left: 0;
      }
    }
  }
  &.nav-toggle{
    display: inline-block;
    cursor: pointer;
    max-width: 2.8rem;
    width: 100%;
    line-height: 0;
    padding:0;
    @include border(0);
    &:before, &:after, span {
      height: .3rem;
      display: block;
      background: $colorSecondary;
    }
    &:before, &:after {
      content: "";
      width: 100%;
      margin: 0;
    }
    span {
      font-size: 0;
      width: 100%;
      margin-top: .4rem;
    }
    &:after {
      margin-top: .4rem;
    }
  }
  @include breakpoint(phone){
    width: 100%;
  }
} /* .action */
.block-dashboard {
  &-addresses, &-orders {
    .block{
      &-title{
        @include flex(space-between, null);
        .action{
          @include fontDefault($lineHeight: 2.2rem, $weight: 500);
          padding: 0;
          &:before{
            display: none;
          }
          span{
            display: block;
          }
        }
      }
    }
  }
}
.no-hover{
  @include breakpoint(tablet){
    background: initial;
    color: initial;
    @include border(0);
  }
  &:hover{
    border-color: transparent;
    background: transparent;
    color: initial;
  }
}

